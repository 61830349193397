import { routes } from "../../routes/route";
import { useNavigate } from "react-router-dom";
import { useContext, useEffect } from "react";
import { AppContext, HubContext } from "../../context";
import UserImage from "../../components/UserImage";

// assets
import logo from "../../assets/images/products-logo.svg";
import gridLines from "../../assets/images/gridlines.svg";
import { HubEvents, OnNotificationHubData } from "../../types/hub";
import { AppState } from "../../context/types";
import { getUserNotificationCount } from "../../services/api/notificationApi";
import {
  Flex,
  Gridlines,
  Item,
  Line,
  Profile,
  ProfileContainer,
  SubText,
  Wrapper,
  Text,
} from "./style";

const Sidebar = ({ route, user: IUser }: any) => {
  const navigate = useNavigate();
  const { user, notificationCount, setNotificationCount, api } =
    useContext(AppContext);
  const { on, off } = useContext(HubContext);

  const handleNotification = (
    payload: OnNotificationHubData,
    state: AppState
  ) => {
    state.setNotificationCount(state.notificationCount + 1);
  };

  useEffect(() => {
    if (api) {
      getUserNotificationCount(api).then((count) => {
        setNotificationCount(count);
      });
    }
  }, [api]);

  useEffect(() => {
    on(HubEvents.onNotification, handleNotification);
    return () => {
      off(HubEvents.onNotification, handleNotification);
    };
  }, []);

  return (
    <Wrapper>
      <img src={logo} alt="logo" />
      <Line />
      {routes.map((item: any, index: number) => (
        <Item
          key={index}
          onClick={() => navigate(item.path)}
          style={{
            borderRadius: item == route ? "100px" : undefined,
            border: item == route ? "3px solid #1dc200" : undefined,
          }}
        >
          <div className="first-bar">
            <img
              src={item == route ? item.activeIcon : item.inactiveIcon}
              alt="icons"
            />
            <Text
              style={{
                fontWeight: item == route ? 600 : 500,
                color:
                  item == route
                    ? "var(--PrimaryBorderDefault)"
                    : "var(--ButtonsSurfaceDisabled)",
              }}
            >
              {item.name}
            </Text>
          </div>
          {item.name === "Notifications" && notificationCount > 0 && (
            <div className="count">{notificationCount}</div>
          )}
        </Item>
      ))}
      <Gridlines src={gridLines} alt="gridlines" />
      <ProfileContainer>
        <Line style={{ margin: "20px auto" }} />
        <Profile>
          <UserImage />
          <Flex>
            <Text style={{ color: "#000" }}>
              {user?.firstName} {user?.lastName}
            </Text>
            <SubText>{user?.email}</SubText>
          </Flex>
        </Profile>
      </ProfileContainer>
    </Wrapper>
  );
};

export default Sidebar;
