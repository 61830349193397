import { Navigate, Route, Routes } from "react-router-dom";
import Projects from "./pages/projects";
import Calls from "./pages/calls";
import Chat from "./pages/chat";
import Settings from "./pages/settings";
import Notifications from "./pages/notifications";
import ChangePassword from "./pages/settings/change-password";
import ScheduledCall from "./pages/calls/scheduled-call/scheduled-call";
import Files from "./pages/files";
import ChatRoom from "./pages/chat/chat-room";
import ScheduleACall from "./pages/calls/schedule-a-call/schedule-a-call";
import ProjectId from "./pages/projects/projectId";
import CreateProjectTab from "./pages/projects/create-project/create-project-tab";

const AppRoutes = () => {
  return (
    <>
      <Routes>
        <Route path="/calls" element={<Calls />} />
        <Route path="/calls/:callId" element={<ScheduledCall />} />
        <Route path="/calls/schedule-a-call" element={<ScheduleACall />} />
        <Route path="/files" element={<Files />} />
        <Route path="/chat" element={<Chat />} />
        <Route path="/chat/:chatId" element={<ChatRoom />} />
        <Route path="/settings" element={<Settings />} />
        <Route path="/settings/change-password" element={<ChangePassword />} />
        <Route path="/notifications" element={<Notifications />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/projects/create-project" element={<CreateProjectTab />} />
        <Route path="/projects/:projectId" element={<ProjectId />} />
        <Route path='/' element={<Navigate replace to="/projects" />} />
        <Route path="*" element={<Projects />} />
      </Routes>
    </>
  );
};

export default AppRoutes;
