import React, { useContext, useMemo, useState } from "react";
import Prompts from "../../../components/Prompts";
import CustomInput from "../../../components/Input";
import useNotification from "../../../hooks/useNotification";
import { Tooltip } from "@mui/material";
import { AppContext } from "../../../context";
import CustomButton from "../../../components/Authentication/custom-buttons";
import { getUploadTokens, uploadToStorage } from "../../../hooks/api/useStorage";
import {
  Wrapper,
  Flex,
  VStack,
  Header,
  Text,
  Section,
  Tabs,
  Select,
  Textarea,
  Attach,
  OthersTextBox,
} from "./style1";
import { IUploadFileMetadataPayload } from "../../../types/file";
import { getFileType } from "../../../services/helpers/helpers";
import { createProject } from "../../../services/api/projectApi";
import { useNavigate } from "react-router-dom";
import SelectProjectDetail from "../select-project-detail";
import { ICreateProject } from "../../../types/project";
import { ILookUp } from "../../../types/lookup";
import CustomSelect from "../../../components/Select";
import {
  Padding,
  PanToolAltRounded,
  PaymentTwoTone,
} from "@mui/icons-material";

// assets
import fileIcon from "../../../assets/icons/file.svg";
import Stepper from "../../../components/Stepper";

const OthersTextBoxStyle = {
  background: "var(--Grey100)",
  border: "1px solid var(--Grey200)",
  outline: "none",
  padding: "10px 16px",
  fontSize: "14px",
  borerRadius: "5px",
  width: "100%",
  fontFamily: "jost",
  color: "#6a7368",
};

const CreateProjectTab = () => {
  const [fileArr, setFileArr] = useState<any>([]);
  const [isLoading, setLoading] = useState(false);
  const { notify } = useNotification();
  const { lookup, isSidebarOpen, api } = useContext(AppContext);
  const [payload, setPayload] = useState<ICreateProject>({
    timeline: lookup?.ProjectTimeline.at(0)?.id,
  });
  const navigate = useNavigate();
  const [displayInputs, setDisplayInputs] = useState<any>();
  const [timeline, setTimeline] = useState(
    lookup?.ProjectTimeline.at(0)?.description ?? ""
  );
  const [error, setError] = useState<any>();

  const getToolTip = () => {
    if (error?.required) {
      return error.required;
    }
    if (error?.budget) {
      return error.budget;
    }
    return "";
  };

  const validation = () => {
    const err: any = {};
    const required = [
      payload.name,
      payload.description,
      payload.category,
      payload.language,
      payload.platform,
      payload.budgetFrom,
      payload.budgetTo,
      payload.timeline,
    ].every((value) => value);

    if (!required) {
      err.required = "Please fill out the required fields";
    }
    if (
      Number(payload.budgetFrom) < 500 ||
      Number(payload.budgetTo) < Number(payload.budgetFrom)
    ) {
      err.budget = "Please check the budget values, Min allowed budget is $500";
    }
    setError(err);
    return !err.required && !err.budget;
  };

  const canSubmit = useMemo(() => {
    return !isLoading && validation();
  }, [payload, isLoading]);

  const handleSubmit = async () => {
    console.log(api);
    if (!validation() || !api) return;
    try {
      setLoading(true);
      if (fileArr.length > 0) {
        const data = await getUploadTokens(api);
        const uploadPromises = fileArr.map(
          async (file: any): Promise<IUploadFileMetadataPayload> => {
            const uploadData = await uploadToStorage(
              data.accountUrl + data.projectFileContainerToken,
              data.projectFileContainerName,
              file[0].name,
              file[0]
            );
            return {
              name: file[0].name,
              sizeBytes: file[0].size,
              projectId: "",
              uri: uploadData.uri,
              type: getFileType(file[0].type, lookup),
            };
          }
        );
        payload.projectFiles = await Promise.all(uploadPromises);
      }
      var project = await createProject(api, payload);
      notify("Project order submitted", "success");
      navigate(`/projects/${project.projectId}`);
    } catch (error: any) {
      notify(
        "Error submitting order, Please try that again or contact support if issue persists",
        "error"
      );
    } finally {
      setLoading(false);
    }
  };

  const handleFileAddition = (file: any) => {
    if (file) {
      const fileExists = fileArr.find((x: any) => x.name === file[0].name);

      if (!fileExists) {
        setFileArr([...fileArr, file]);
      }
    }
  };

  const handleFileDeletion = (file: any) => {
    const newArr = fileArr.filter((item: any) => item[0].name !== file[0].name);
    setFileArr(newArr);
  };

  const handleFormChange = (name: string, value: any) => {
    if (name === "timeline") {
      setTimeline(
        lookup?.ProjectTimeline.find((item) => item.id === value)
          ?.description ?? ""
      );
    }
    const newPayload = {
      ...payload,
      [name]: value,
    };
    setPayload(newPayload);
  };

  const handleLookupSelected = (name: string, value: ILookUp) => {
    handleFormChange(name, value.id);
    setDisplayInputs({
      ...displayInputs,
      [name]: value.key === "other",
    });
  };

  return (
    <Wrapper>
      <Stepper index={1} />
      <div className="flex_1">
        <Flex>
          <VStack>
            <Header>Create a project</Header>
            <Text>Submit an order for a project</Text>
          </VStack>
          <Tooltip title={getToolTip()} open={true}>
            <div>
              <CustomButton
                title="Create Project"
                type="button"
                style={{
                  background: `${
                    canSubmit ? "var(--PrimarySurfaceLighter)" : "#90998F"
                  }`,
                  color: `${
                    canSubmit ? "var(--GreyscaleSurfaceSubtle)" : "#B5BBB4"
                  }`,
                  padding: "10px 25px",
                  borderRadius: "100px",
                }}
                isLoading={isLoading}
                disabled={!canSubmit}
                onClick={handleSubmit}
              />
            </div>
          </Tooltip>
        </Flex>
      </div>
      <Section>
        <Prompts title="Project name" />
        <CustomInput
          placeholder="Enter project name"
          type="text"
          value={payload.name}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            handleFormChange("name", e.target.value)
          }
        />
      </Section>
      <Section>
        <SelectProjectDetail
          title="What do you want to order?"
          lookUp={lookup?.ProjectCategory}
          onSelect={(e) => {
            handleLookupSelected("category", e);
          }}
          grid
        />
        <OthersTextBox
          placeholder="What do you want to order"
          disabled={!displayInputs?.category}
          onChange={(e) => handleFormChange("otherCategory", e.target.value)}
          style={{
            ...OthersTextBoxStyle,
            background: !displayInputs?.category ? "#EFF0EF" : "none",
          }}
        />
      </Section>
      <Section>
        <SelectProjectDetail
          title="What is your desired solution language?"
          lookUp={lookup?.ProjectSolutionLanguage}
          onSelect={(e) => {
            handleLookupSelected("language", e);
          }}
          grid={false}
        />
        <OthersTextBox
          placeholder="What is your desired solution language"
          disabled={!displayInputs?.language}
          onChange={(e) => handleFormChange("otherLanguage", e.target.value)}
          style={{
            ...OthersTextBoxStyle,
            background: !displayInputs?.language ? "#EFF0EF" : "none",
          }}
        />
      </Section>
      <Section>
        <SelectProjectDetail
          title="Choose your desired platform"
          lookUp={lookup?.ProjectSolutionPlatform}
          onSelect={(e) => {
            handleLookupSelected("platform", e);
          }}
          grid={false}
        />
        <OthersTextBox
          placeholder="What is your desired platform"
          disabled={!displayInputs?.platform}
          onChange={(e) => handleFormChange("otherPlatform", e.target.value)}
          style={{
            ...OthersTextBoxStyle,
            background: !displayInputs?.platform ? "#EFF0EF" : "none",
          }}
        />
      </Section>
      <Section>
        <Prompts title="Project description" />
        <Textarea
          placeholder="Enter project description"
          rows={6}
          value={payload.description}
          onChange={(e) => handleFormChange("description", e.target.value)}
        />
      </Section>
      <Section>
        <Flex>
          <img src={fileIcon} alt="file" />
          <Attach>Attach files</Attach>
        </Flex>

        <input
          type="file"
          onChange={(e: any) => handleFileAddition(e.target.files)}
          style={{ position: "absolute", opacity: 0 }}
        />
      </Section>
      <Tabs>
        {fileArr.map((file: any) => (
          <Select
            key={file.lastModified}
            onClick={() => handleFileDeletion(file)}
            style={{
              backgroundColor: "var(--PrimarySurfaceDefault)",
              color: "#FFF",
              padding: "6px 16px",
              width: "fit-content",
              marginBottom: "-20px",
              textTransform: "capitalize",
              cursor: "pointer",
            }}
          >
            {file[0].name.split(".")[0].substring(0, 16)}
          </Select>
        ))}
      </Tabs>
      <Section>
        <VStack>
          <Header>Budget and estimated period</Header>
          {/* <Text style={{ fontSize: "17px" }}>
            {selectedDate.to && changeDateFormat(selectedDate.to)}
          </Text> */}
        </VStack>
      </Section>
      <Section className="negotiable_budget">
        <Text>Negotiable budget</Text>
        <Flex className="flex-budget">
          <VStack>
            <Prompts title="From" />
            <CustomInput
              placeholder="500"
              value={payload.budgetFrom?.toString()}
              half
              type="number"
              icon="$"
              style={{
                padding: "10px 16px 10px 40px",
              }}
              iconPosition={{ position: "absolute", left: "20px" }}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleFormChange("budgetFrom", e.target.value)
              }
              min={500}
            />
          </VStack>
          <Text style={{ marginTop: "20px" }}>to</Text>
          <VStack>
            <Prompts title="To" />
            <CustomInput
              placeholder="1000"
              value={payload.budgetTo?.toString()}
              half
              type="number"
              icon="$"
              style={{
                padding: "10px 16px 10px 40px",
              }}
              iconPosition={{ position: "absolute", left: "20px" }}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleFormChange("budgetTo", e.target.value)
              }
              min={payload.budgetFrom}
            />
          </VStack>
        </Flex>
        <Flex className="flex-budget">
          <VStack>
            <Prompts title="Estimated timeline" />
            <CustomSelect
              value={payload.timeline ?? 0}
              title={timeline}
              size="size"
              name="timeline"
              handleChange={(e) => handleFormChange("timeline", e.target.value)}
              data={
                lookup?.ProjectTimeline.map((item) => {
                  return { title: item.description, value: item.id };
                }) ?? []
              }
            />
          </VStack>
        </Flex>
      </Section>
    </Wrapper>
  );
};

export default CreateProjectTab;
