import styled from "@emotion/styled";

export const FilePickerStyle = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  .file-types {
    position: absolute;
    margin: auto;
    width: 99%;
  }
`;

export const FileTypeModalItem = styled.li`
  list-style: none;
  width: 140px;
  margin: auto;
  @media(min-width: 321px){
    bottom: 80px;
  }
  @media(max-width: 320px){
    bottom: 106px;
  }
  height: 45px;
  border: none;
  border-bottom: solid 1px #D8D8D8;
  padding: 12px 16px 12px 16px;
  background: #F7F8F7;
  font-family: jost;
  font-size: 14px;
  line-height: 21px;
  font-weight 400;
  color: #222522;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  :hover{
    background: #E2E4E2;
  }
`;

export const FilePickerBox = styled.section`
  padding-top: 25px;
  padding-bottom: 20px;
`;
