import styled from "@emotion/styled";

export const Header = styled.div`
  color: var(--GreyscaleTextIconBody);
  font-size: 30px;
  font-weight: 700;
  line-height: 150%;
  letter-spacing: -1.8px;

  @media (min-width: 992px) and (max-width: 1300px) {
    font-size: 28px;
  }
`;
export const Empty = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
`;
export const EmptyContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 30px;
  margin-top: 50px;

  @media (max-width: 576px) {
    > img {
      width: 100%;
    }
    > div:nth-of-type(1) {
      text-align: center;
    }
  }
`;
export const Text = styled.div`
  color: var(--GreyscaleTextIconBody);
  line-height: 150%;
  font-size: 17px;
  letter-spacing: 0.5px;
`;
export const Hr = styled.hr`
  border: 1px solid var(--GreyscaleSurfaceDefault);
  margin: 15px -40px;

  @media (min-width: 992px) and (max-width: 1300px) {
    margin: 15px -20px;
  }
  @media (max-width: 991px) {
    margin: 15px -15px;
  }
`;
export const Container = styled.section`
  display: flex;
  flex-direction: column;
  margin: -20px -40px;
  cursor: pointer;
  overflow-y: auto;

  .loader {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
  }
`;
export const Card = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  background-color: var(--WhiteSurface);
  padding: 15px 40px 15px 30px;
  margin-bottom: 15px;

  .time {
    position: absolute;
    right: 10px;
  }
`;
export const TextContainer = styled.div`
  margin-right: auto;
  margin-left: 15px
`;
export const Text1 = styled.div`
  color: var(--GreyscaleSurfaceBtBackground);
  font-size: 17px;
  line-height: 25.5px;
  font-weight: 500;
  margin-bottom: 5px;
`;
export const Text2 = styled.div`
  font-size: 14px;
  line-height: 21px;
  color: var(--GreyscaleTextIconBody);
`;