import { Typography } from "@mui/material";
import BriefCase from "../../../assets/icons/brief-case.svg";
import UploadToCloudBtn from "../../../assets/icons/white-upload-to-cloud-button.svg";
import UploadToCloudBtnWhite from "../../../assets/icons/upload-to-cloud-white.svg";
import { useContext, useEffect, useState } from "react";
import useApi from "../../../hooks/api/useApi";
import { Button, SelectionBox } from "./style";
import useNotification from "../../../hooks/useNotification";
import { IProjectId } from "../../../types/project";
import { getUserProjectIds } from "../../../services/api/projectApi";
import { AppContext } from "../../../context";

interface IProjectPickerProps {
  title?: string;
  buttonText?: string;
  open: boolean;
  onSubmit: (project: IProjectId) => void;
}

export const ProjectPicker = ({
  onSubmit,
  title,
  buttonText,
  open,
}: IProjectPickerProps) => {
  const [projects, setProjects] = useState<IProjectId[]>([]);
  const [selectedProject, setSelectedProject] = useState<
    IProjectId | undefined
  >();
  const [isLoading, setIsLoading] = useState(true);
  const { api } = useContext(AppContext);
  const { notify } = useNotification();

  const getSelectedProjectStyle = (project: IProjectId) => {
    if (selectedProject && selectedProject.projectId == project.projectId) {
      return {
        background: "#297DFD",
        border: "none",
      };
    }
    return {
      background: "none",
      border: "solid #6a7368",
    };
  };

  const getDisabledButtonStyle = () => {
    if (selectedProject) {
      return {
        background: "#1DC200",
        color: "#ffffff",
      };
    }
    return {
      background: "#90998f",
      color: "#e2e4e2",
    };
  };

  const getProjects = () => {
    api &&
      getUserProjectIds(api)
        .then((projects) => {
          setProjects(projects);
        })
        .catch((error) => {
          notify("failed to fetch projects, please try again", "error");
        })
        .finally(() => {
          setIsLoading(false);
        });
  };

  const handleProjectSelected = (project: IProjectId) => {
    setSelectedProject(project);
  };

  const handleSubmit = () => {
    if (selectedProject) {
      onSubmit(selectedProject);
    }
  };

  useEffect(() => {
    if (api) {
      getProjects();
    }
  }, [api]);

  return (
    <div className="file-Upload-container" hidden={!open}>
      <SelectionBox>
        <div className="file-upload-header">
          <img src={BriefCase} />
          <Typography variant="h4">{title ?? "Select project"}</Typography>
        </div>
        <div style={{ overflow: "auto", height: "260px", cursor: "pointer" }}>
          {isLoading ? (
            <>
              <div className="fake-select-association">
                <div></div>
              </div>
              <div className="fake-select-association">
                <div></div>
              </div>
              <div className="fake-select-association">
                <div></div>
              </div>
            </>
          ) : (
            <>
              {projects.map((item) => (
                <div
                  className="select-association"
                  onClick={() => handleProjectSelected(item)}
                >
                  <Typography>{item.projectName}</Typography>
                  <Button
                    onClick={() => handleProjectSelected(item)}
                    style={getSelectedProjectStyle(item)}
                  ></Button>
                </div>
              ))}
            </>
          )}
        </div>
        <div className="file-upload-button">
          <button
            style={getDisabledButtonStyle()}
            disabled={selectedProject == null}
            onClick={handleSubmit}
          >
            {buttonText ?? "Submit"}
            {selectedProject ? (
              <img src={UploadToCloudBtn} />
            ) : (
              <img src={UploadToCloudBtnWhite} />
            )}
          </button>
        </div>
      </SelectionBox>
    </div>
  );
};
export default ProjectPicker;
