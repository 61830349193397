import styled from "@emotion/styled";

export const Wrapper = styled.section`
  position: relative;
  text-align: center;
  height: 100vh;
  background: var(--GreyscaleSurfaceSubtle);
  max-width: 350px;
  width: 100%;
  margin-right: 15px;
  border: 1px solid var(--Grey200);
  padding: 30px 20px;
  overflow: hidden;
  
  @media (max-height: 600px) {
    overflow-y: scroll;
  }
  @media (min-width: 992px) and (max-width: 1300px) {
    max-width: 250px;
    padding: 30px 10px;

    > img:nth-of-type(1) {
      width: 200px;
    }
  }
  @media (max-width: 991px) {
    padding: 0;
    max-width: 0px;
    margin-right: 0px;
  }
`;
export const Line = styled.hr`
  border: 1px solid var(--GreyscaleBorderDisabled);
  margin: 35px auto;
`;
export const Item = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 10px;
  cursor: pointer;
  padding: 10px 20px;
  margin-bottom: 15px;
  position: relative;
  z-index: 10;
  .first-bar {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .count {
    background: #297dfd;
    color: white;
    border-radius: 100px;
    width: 30px;
    padding: 10px;
  }
`;
export const Gridlines = styled.img`
  position: absolute;
  width: 60vw;
  left: -33vw;
  bottom: -40vh;

  @media (min-width: 992px) and (max-width: 1400px) {
    width: 80vw;
    left: -45vw;
    bottom: -20vh;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    width: 100vw;
    left: -45vw;
    bottom: -20vh;
  }
`;
export const Text = styled.div`
  font-size: 17px;
  line-height: 150%;
  font-weight: 600;

  @media (min-width: 992px) and (max-width: 1300px) {
    font-size: 15px;
  }
`;
export const SubText = styled.div`
  font-size: 14px;
  line-height: 150%;

  @media (min-width: 992px) and (max-width: 1300px) {
    font-size: 12px;
  }
`;
export const ProfileContainer = styled.div`
  position: relative;
  bottom: 0;
  width: 88.5%;
`;
export const Profile = styled.div`
  display: flex;
  column-gap: 10px;

  @media (min-width: 992px) and (max-width: 1300px) {
    > img:nth-of-type(1) {
      width: 15%;
    }
  }
`;
export const Flex = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;