import styled from "@emotion/styled";

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--PrimarySurfaceSubtitle);
  margin: -20px -40px 20px;
  padding: 20px 40px;
  position: fixed;
  z-index: 1000;
  width: calc(100vw - 350px);

  .phone {
    margin-right: 20px;

    @media (max-width: 1300px) {
      margin-right: 15px;
    }
  }
  .icons {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  @media (min-width: 992px) and (max-width: 1300px) {
    width: calc(100vw - 250px);
    margin: -20px -20px;
    padding: 20px;

    .icons {
      margin-right: 10px;
    }
  }
  @media (max-width: 991px) {
    width: 100vw;
    margin: -20px -15px;
    padding: 15px;
  }
`;
export const Deets = styled.div`
  margin-right: auto;
  margin-left: 15px;

  > div:nth-of-type(1) {
    font-size: 17px;
    line-height: 25.5px;
    font-weight: 600;
    color: var(--GreyscaleTextIconBody);
  }
  > div:nth-of-type(2) {
    font-size: 14px;
    line-height: 21px;
    color: var(--GreyscaleTextIconSubtitle);
  }
`;
export const Wrapper = styled.section`
    background-color: var(--WhiteSurface);
    height: calc(100vh - 120px - 80px);
    width: calc(100vw - 350px - 13px);
    margin-left: -40px;
    padding: 75px 40px 45px;
    overflow-y: auto;

    article {
        display: flex;
        align-items: center;
    }
    .me_article {
        justify-content: space-between;
    }

    .loader {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 10px;
    }
    .date {
        margin: 15px auto;
        text-align: center;
        background-color: var(--Grey100);
        font-size: 12px;
        font-weight: 500;
        line-height: 15.12px;
        color: var(--GreyscaleTextIconBody);
        padding: 4px 16px 2px;
        border-radius: 100px;
        max-width: fit-content;
        cursor: pointer;
    }
    .message_container {
        display: flex;
        flex-direction: column;
        row-gap: 5px;

        .icon_text {
          display: flex;
          align-items: center;
          margin: 10px 0px 2px;
          column-gap: 10px;
          font-size: 14px;
          line-height: 21px;
          color: var(--GreyscaleBorderDarker)
        }
    }
    .message_content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      row-gap: 3px;
      justify-content: flex-end;
      padding: 8px;
      border-radius: 12px;
      font-size: 14px;
      line-height: 21px;
      color: var(--GreyscaleTextIconBody);
      max-width: 250px;
      min-width: 150px;
      width: fit-content;

      img {
        width: 100%;
        height: 260px;
        border-radius: 8px;
        object-fit: cover;
      }
    }
    .message_type {
      padding: 8px;
      border-radius: 12px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      row-gap: 7px;

      > div:nth-of-type(1) {
        display: flex;
        align-items: center;
        column-gap: 10px;

        div {
          font-size: 12px;
          line-height: 18px;
        }
        .name {
          font-size: 14px;
          line-height: 21px;
          color: var(--GreyscaleTextIconBody);
          font-weight: 500;
        }
        .cl {
          color: var(--GreyscaleTextIconSubtitle);
          display: flex; 
          align-items: center;
          column-gap: 5px;

          div {
            width: 4px;
            height: 4px;
            background: var(--GreyscaleTextIconSubtitle);
            border-radius: 2px;
          }
        }
        .time {
          color: var(--ButtonsSurfaceSecondaryHover);
        }
      }
      > div:nth-of-type(2) {
        display: flex;
        flex-direction: column;
        row-gap: 5px;

        .status {
          padding: 2px 8px;
          width: 220px;
          border-radius: 100px;
          font-size: 12px;
          line-height: 18px;
          font-weight: 500;
          display: flex;
          align-items: center;
          justify-content: center;
          column-gap: 5px;
          text-transform: uppercase;
          cursor: pointer;

          div {
            width: 8px;
            height: 8px;
            border-radius: 4px;
          }
        }
        .status:hover {
          transform: scale(0.98);
        }

        .project {
          background-color: var(--Grey200);
          color: var(--GreyscaleTextIconSubtitle);
          text-transform: none;
          padding: 4px 8px;
        }
      }
    }
    #me {
        background-color: var(--PrimarySurfaceSubtitle);
    }
    #algocipher {
        background-color: var(--Grey50);
    }
    .time {
        font-size: 10px;
        line-height: 12px;
        color: var(--GreyscaleTextIconSubtitle);
        font-family: "Outfit", sans-serif;
        margin-left: auto;
    }


    @media (min-width: 992px) and (max-width: 1300px) {
        width: calc(100vw - 250px - 13px);
        margin-left: -20px;
        padding: 75px 20px 45px;
    }
    @media (max-width: 991px) {
        width: 100vw;
        margin-left: -15px;
        margin-right: -15px;
        padding: 65px 8px 35px;
    }
`;
export const InputContainer = styled.div`
  margin: -20px -40px;
  padding: 20px 48px;
  min-height: 88px;
  background-color: var(--GreyscaleTextIconNegative);
  position: fixed;
  bottom: 20px;
  box-shadow: 0px -5px 22px 0px #10192824;
  width: calc(100vw - 350px);
  display: flex;
  align-items: center;
  column-gap: 15px;

  .green_btn {
    width: 28px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 40px;

    @media (min-width: 992px) and (max-width: 1300px) {
      right: 30px;
    }
    @media (max-width: 991px) {
      right: 10px;
    }
  }

  .css-1e6y48t-MuiButtonBase-root-MuiButton-root:hover {
    background-color: transparent !important;
  }

  article {
    display: flex;
    align-items: center;
    column-gap: 15px;
    width: 100%;

    div {
      margin-right: auto !important;
      font-size: 20px;
      line-height: 30px;
      color: var(--GreyscaleTextIconBody);
      font-weight: 500;
      margin-right: auto;
    }
  }

  textarea {
    padding: 10px 16px;
    border: 1px solid var(--GreyscaleBorderDisabled);
    border-radius: 8px;
    color: var(--GreyscaleTextIconSubtitle);
    font-size: 14px;
    line-height: 21px;
    outline: none;
    height: 48px;
    width: 100%;
    resize: none;

    @media (max-width: 1300px) {
      width: calc(100% - 5px);
    }
  }
  textarea::placeholder {
    font-size: 14px;
    line-height: 21px;
  }

  @media (min-width: 992px) and (max-width: 1300px) {
    width: calc(100vw - 250px);
    margin: -20px -20px;
    padding: 20px 28px;
  }
  @media (max-width: 991px) {
    width: 100vw;
    margin: -20px -15px;
    padding: 15px;
  }
`;
export const CancelBtn = styled.div`
    border: 1px solid #297DFD;
    padding: 8px 16px;
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
    color: #297DFD;
    border-radius: 100px;
    cursor: pointer;
    width: 50%;
    text-align: center;

    @media (max-width: 397px) {
        width: 100%;
    }
`;
export const DeleteBtn = styled.div`
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
    color: var(--WhiteSurface);
    background: var(--PrimaryBorderLighter);
    padding: 8px 16px;
    border-radius: 100px;
    cursor: pointer;
    width: 50%;
    text-align: center;

    @media (max-width: 397px) {
        width: 100%;
    }
`