import styled from "@emotion/styled";

export const SelectionBox = styled.div`
  z-index: 2;
  width: 422px;
  height: auto;
  @media (max-width: 600px) {
    width: clamp(150px, 80vw, 422px);
  }
  margin: auto;
  background: #f7f8f7;
  box-shadow: 0px 4px 10px 10px #d8d8d8;
  .file-upload-header {
    display: flex;
    background: #f7f8f7;
    align-items: center;
    gap: 10px;
    width: 100%;
    height: 48px;
    border: 0px 0px 1px 0px;
    padding: 12px 16px 12px 16px;
    border-bottom: solid 1px #eff0ef;
    h4 {
      font-family: jost;
      font-weight: 500;
      font-size: clamp(5px, 3vw, 14px);
      line-height: 21px;
      color: #222522;
    }
  }
  .select-association {
    display: flex;
    border: solid red;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: auto;
    height: 64px;
    padding: 12px 16px 12px 16px;
    border: solid 1px #d8d8d8;
  }
  .file-upload-button {
    width: 100%;
    height: 56px;
    background: #f7f8f7;
    padding: 8px 16px 8px 16px;
    button {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      background: #90998f;
      border: none;
      border-radius: 100px;
      color: #e2e4e2;
      font-size: 14px;
      font-family: jost;
      font-weight: 700;
      line-height: 21px;
      width: 98%;
      height: 40px;
      margin: auto;
      cursor: pointer;
      img {
        width: 12.92x;
        height: 14.15px;
        color: #e2e4e2;
      }
    }
  }
  .fake-select-association {
    display: flex;
    border: solid red;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: auto;
    height: 64px;
    border: solid 1px #d8d8d8;
    background: #90998f;
    div {
      height: 90%;
      width: 10px;
      background: #ffffff;
      box-shadow: 0px 0px 20px 15px #ffffff;
      animation: animate 1.5s infinite alternate ease-in-out;
      @keyframes animate {
        from {
          translate: 0px;
        }
        to {
          translate: 400px;
        }
      }
    }
  }
`;

export const Button = styled.div`
  border: solid #6a7368;
  border-radius: 50%;
  height: 33.33px;
  width: 33.33px;
  cursor: pointer;
`;