import React, { useContext, useEffect, useState } from "react";
import Proposals from "../../../components/Proposals/index";
import Button from "../../../components/Button";
import { ArrowRight, X } from "phosphor-react";
import CounterProposals from "../../../components/Proposals/counter-proposals";
import {
  changeDateFormat,
  changeDateFormatFromISO,
  changeDateFormatToTimelineFormat,
} from "../../../services/helpers/useChangeDateFormat";
import {
  acceptProposal,
  cancelProject,
  counterProposal,
} from "../../../services/api/projectApi";
import useNotification from "../../../hooks/useNotification";
import { IGetProject } from "../../../types/project";
import { Wrapper, VStack, Header, Text, Spacer } from "./style2";
import { useNavigate, useParams } from "react-router-dom";
import { AppContext } from "../../../context";

interface ProposalsProps {
  icon: "user" | "algocipher";
  index?: number;
  text: string;
  subText: string;
  budget: { from: number; to: number; cost?: number };
  timeline: string;
}
interface BudgetApprovalTabProps {
  selectedProject: IGetProject;
  setIndex: React.Dispatch<React.SetStateAction<number>>
}

const BudgetApprovalTab = ({ selectedProject, setIndex }: BudgetApprovalTabProps) => {
  const { projectId } = useParams();
  const { proposals } = selectedProject || {};
  const [userProposalsArr, setUserProposalsArr] = useState<ProposalsProps[]>(
    []
  );
  const [algocipherProposalsArr, setAlgocipherProposalsArr] = useState<
    ProposalsProps[]
  >([]);
  const maxArrayLength = Math.max(
    userProposalsArr.length,
    algocipherProposalsArr.length
  );
  const [projectCancellation, setProjectCancellation] = useState(false);
  const [showCounterProposal, setShowCounterProposal] = useState(true);
  const [isDisabled, setIsDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [hideBtns, setHideBtns] = useState(false);
  const { notify } = useNotification();
  const navigate = useNavigate();
  const { api, lookup } = useContext(AppContext);

  // counter proposals
  const [counterBudget, setCounterBudget] = useState({
    from: "",
    to: "",
    cost: "",
  });
  const [counterTimeline, setCounterTimeline] = useState(
    lookup?.ProjectTimeline.at(0)?.id
  );

  const handleCounterProposalClick = () => {
    setShowCounterProposal(false);
    setCounterBudget({ from: "", to: "", cost: "" });
    setCounterTimeline(lookup?.ProjectTimeline.at(0)?.id);
    setIsLoading(false);
  };

  const handleGoBack = () => {
    setShowCounterProposal(true);
    setCounterBudget({ from: "", to: "", cost: "" });
  }

  const formatCounterText = (index: number, entity: 'user' | 'algocipher') => {
    let suffix = "";
  
    if (index === 1) suffix = "1st";
    else if (index === 2) suffix = "2nd";
    else if (index === 3) suffix = "3rd";
  
    if (entity === 'user') {
      return index === 0
        ? "Awaiting Algocipher’s response on proposed budget and timeline"
        : `${suffix} counter proposal on budget and timeline`;
    }
  
    return index === 0
      ? "Algocipher's update on budget and timeline"
      : `Algocipher's update on ${suffix} budget and timeline`;
  };

  const handleCancelProject = async () => {
    try {
      projectId && api && (await cancelProject(api, projectId));
      setProjectCancellation(true);
      notify("Project cancelled successfully", "success");
    } catch (error: any) {
      notify(
        "Error canceling proposal, Please try that again or contact support if issue persists",
        "error"
      );
    }
  };

  const handleAcceptProposal = async () => {
    if (!api) return;
    try {
      setIsLoading(true);
      const proposalId = proposals?.find(
        (item) => item.status !== "denied"
      )?.id;
      projectId &&
        proposalId &&
        (await acceptProposal(api, projectId, { proposalId: proposalId }));
      notify("Budget and timeline approved", "success");
      setIndex(3)
    } catch (error: any) {
      notify(
        "Error accepting proposal, Please try that again or contact support if issue persists",
        "error"
      );
    } finally {
      setIsLoading(false)
    }
  };

  const handleCounterProposal = async () => {
    try {
      setIsLoading(true);
      const payload = {
        cost: Number(counterBudget.cost),
        timeline: counterTimeline,
      };

      if (projectId && api) {
        await counterProposal(api, projectId, payload);
        setShowCounterProposal(true);
        setHideBtns(true);
        // refresh proposals
      }
    } catch (error: any) {
      notify(
        "Error countering proposal, Please try that again or contact support if issue persists",
        "error"
      );
    } finally {
      setIsLoading(false)
    }
  };

  const getTimeline = (key: string) => {
    return lookup?.ProjectTimeline.find((item) => item.key === key)
      ?.description;
  };

  useEffect(() => {
    if (proposals) {
      proposals.forEach((item, index) => {
        const userProposal: ProposalsProps = {
          icon: "user",
          index: 2,
          text: formatCounterText(index, "user"),
          subText: changeDateFormatFromISO(item.submittedAt),
          budget: { from: item.budgetFrom, to: item.budgetTo, cost: item.cost },
          timeline: item.timeline,
        };

        setUserProposalsArr((prevArr) => [...prevArr, userProposal]);

        if (item.reply) {
          const algocipherProposal: ProposalsProps = {
            icon: "algocipher",
            index: 2,
            text: formatCounterText(index, "algocipher"),
            subText: changeDateFormatFromISO(item.reply.submittedAt),
            budget: {
              from: item.reply.budgetFrom,
              to: item.reply.budgetTo,
              cost: item.reply.cost,
            },
            timeline: item.timeline,
          };

          setAlgocipherProposalsArr((prevArr) => [
            ...prevArr,
            algocipherProposal,
          ]);
        }
      });
    }
  }, [proposals]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (projectCancellation) {
        navigate(-1)
      }
    }, 2000);

    return () => clearTimeout(timeoutId);
  }, [projectCancellation]);

  useEffect(() => {
    if (counterBudget.cost) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [counterBudget.cost]);

  return (
    <>
      {!projectCancellation ? (
        <Wrapper>
          <div className="flex_1">
            <VStack>
              <Header>Budget Approval</Header>
              <Text>You have a maximum of 3 counter proposals for budget</Text>
            </VStack>
          </div>

          {Array.from({ length: maxArrayLength }, (_, index) => (
            <React.Fragment key={index}>
              {userProposalsArr[index] !== undefined && (
                <Proposals
                  icon={userProposalsArr[index].icon}
                  index={userProposalsArr[index].index}
                  text={userProposalsArr[index].text}
                  subText={userProposalsArr[index].subText}
                  budget={userProposalsArr[index].budget}
                  timeline={getTimeline(userProposalsArr[index].timeline)}
                  hide={userProposalsArr.length - 1 > index}
                />
              )}

              {algocipherProposalsArr[index] !== undefined && (
                <Proposals
                  icon={algocipherProposalsArr[index].icon}
                  index={algocipherProposalsArr[index].index}
                  text={algocipherProposalsArr[index].text}
                  subText={algocipherProposalsArr[index].subText}
                  budget={algocipherProposalsArr[index].budget}
                  timeline={getTimeline(algocipherProposalsArr[index].timeline)}
                  hide={algocipherProposalsArr.length - 1 > index}
                />
              )}
            </React.Fragment>
          ))}

          {proposals.length > 0 && !showCounterProposal && (
            <CounterProposals
              icon="user"
              text={formatCounterText(proposals.length, "user")}
              subText={changeDateFormat(new Date().toISOString().split("T")[0])}
              budget={counterBudget}
              timeline={counterTimeline}
              setBudget={setCounterBudget}
              setTimeline={setCounterTimeline}
            />
          )}

          {userProposalsArr.length === algocipherProposalsArr.length && !hideBtns &&
            selectedProject && (
              <Spacer>
                <Button
                  title={
                    showCounterProposal
                      ? proposals.length === 4
                        ? "Cancel project"
                        : "Counter Proposal"
                      : "Go Back"
                  }
                  bg="var(--GreyscaleSurfaceBtBackground)"
                  color="var(--GreyscaleSurfaceSubtle)"
                  w="48%"
                  budget
                  iconRight
                  icon={
                    showCounterProposal && proposals.length === 4 ? (
                      <X
                        size={20}
                        weight="bold"
                        color="var(--GreyscaleSurfaceSubtle)"
                      />
                    ) : (
                      <ArrowRight
                        size={20}
                        weight="thin"
                        color="var(--GreyscaleSurfaceSubtle)"
                      />
                    )
                  }
                  onClick={() =>
                    showCounterProposal
                      ? proposals.length === 4
                        ? handleCancelProject()
                        : handleCounterProposalClick()
                      : handleGoBack()
                  }
                />

                <Button
                  title={
                    showCounterProposal
                      ? "Accept Proposal"
                      : "Submit counter offer"
                  }
                  bg="var(--PrimaryBorderLighter)"
                  color="#FFF"
                  w="48%"
                  budget
                  iconRight
                  icon={<ArrowRight size={20} weight="thin" color="#FFF" />}
                  isLoading={isLoading}
                  onClick={() =>
                    showCounterProposal
                      ? handleAcceptProposal()
                      : !isDisabled && handleCounterProposal()
                  }
                  disabled={!showCounterProposal && (isDisabled || isLoading)}
                />
              </Spacer>
            )}
        </Wrapper>
      ) : (
        <Wrapper>
          <VStack>
            <Header>Project Cancellation</Header>
            <Text>You have a maximum of 3 counter proposals for budget</Text>
          </VStack>
        </Wrapper>
      )}
    </>
  );
};

export default BudgetApprovalTab;
