import { ApiResponse, Page, PagedApiResponse } from "../../types/apiResponse";
import { IApi, IQueryParams } from "../../types/apiTypes";
import { IFile, IUploadFileMetadataPayload } from "../../types/file";

export const getUserFiles = (
  api: IApi,
  query: IQueryParams
): Promise<Page<IFile>> => {
  const url = "file";
  return api
    .getPage(url, query)
    .then((result: PagedApiResponse<IFile>) => {
      return result.data;
    })
    .catch((error) => {
      return new Promise((resolve, reject) => {
        reject(error);
      });
    });
};

export const saveFileMetaData = (
  api: IApi,
  payload: IUploadFileMetadataPayload
): Promise<boolean> => {
  const url = "file";
  return api
    .post(url, payload)
    .then((result: ApiResponse<boolean>) => {
      return result.data;
    })
    .catch((error) => {
      return new Promise((resolve, reject) => {
        reject(error);
      });
    });
};
