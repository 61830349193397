import styled from "@emotion/styled";

export const Wrapper = styled.section`
  .flex-end {
    align-items: flex-end;
  }
`;
export const Container = styled.div`
  background: var(--Grey100);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 500px;
  width: 100%;
  padding: 20px;
  margin: auto;

  @media (min-width: 992px) and (max-width: 1300px) {
    max-width: 450px
  }
  @media (max-width: 991px) {
    max-width: calc(100vw - 35px);
  }
`;
export const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%;

  @media (max-width: 991px) {
    width: 100%;
  }
`;
export const Col = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
`;
export const Spacer = styled.div`
  margin: 20px auto;
  width: 80%;

  @media (max-width: 991px) {
    width: 100%;
  }
`;
export const Header = styled.div`
  font-size: 24px;
  color: #000;
  font-weight: 500;
  line-height: 150%; 
  margin: 10px auto;
`;
export const Text1 = styled.div`
  font-size: 14px;
  color: var(--GreyscaleTextIconBody);
  line-height: 150%;
  text-align: center;
`;
export const SubHeader = styled.div`
  font-size: 14px;
  color: var(--GreyscaleSurfaceBtBackground);
  font-weight: 600;
  line-height: 150%;
  margin: 20px auto 10px;
  width: 80%;

  @media (max-width: 991px) {
    width: 100%;
  }
`;
export const Text2 = styled.div`
  font-size: 14px;
  color: var(--GreyscaleSurfaceDisabled);
  line-height: 150%; 
`;
export const Text3 = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: var(--GreyscaleTextIconBody);
  line-height: 150%; 
`;
