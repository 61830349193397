import styled from "@emotion/styled";

export const PreviewStyle = styled.section`
  position: absolute;
  margin-left: -40px;
  margin-top: -20px;
  @media (max-width: 425px) {
    margin-left: -15px;
  }
  width: 110%;
  height: 379px;
  .preview-first-block {
    margin-left: 0;
    background: #dcffd6;
    padding: 20px 32px 20px 32px;
    display: flex;
    justify-content: center;
    h4 {
      width: 100%;
      text-align: center;
      font-size: 20px;
      font-weight: 600;
      font-family: jost;
      line-height: 30px;
      color: #222522;
    }
    img {
      padding-left: 15px;
    }
  }
  .show-preview {
    width: 75%;
    margin: auto;
    aspect-ratio: 10/5;
    margin-top: 30px;
  }
  .send-preview {
    background: #E2E4E2;
    filter: drop-shadow(0px -10px 10px #10192824);
    margin-top: 50px;
    width: 99%;
    padding: 16px 32px 16px 32px;
    div {
      width: 95%;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      @media (min-width: 1600px) {
        width: 85%;
      }
      @media (max-width: 430px) {
        display: flex;
        width: 100%;
        margin: auto;
        justify-content: space-between;
        #embed {
          display: none;
        }
        img {
          margin: auto;
        }
      }
      margin: auto;
      height: auto;
      border-radius: 8px;
      padding: 8px 12px 8px 0px;
      background: #ffffff;
      text-align: center;
      #embed {
      }
      img {
        width: 40px;
      }
      input {
        width: 90%;
        height: 100%;
        font-size: 14px;
        font-weight: 400;
        font-family: jost;
        color: #6a7368;
        border: none;
        background: none;
        outline: none;
      }
    }
  }
`;
