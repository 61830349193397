import { useNavigate, useParams } from "react-router-dom";
import { useState, useEffect, useContext } from "react";
import { ArrowLeft, CaretDown } from "phosphor-react";
import Prompts from "../../../components/Prompts";
import Flashcard from "../../../components/Flashcard";
import {
  Header,
  Section,
  Col,
  Subcol,
  Text,
  Flex,
  SubFlex,
} from "./scheduled-call-style";
import { deleteCall, getSingleCall } from "../../../services/api/callApi";
import useApi from "../../../hooks/api/useApi";
import useNotification from "../../../hooks/useNotification";
import { ISingleCall } from "../../../types/call";
import { DateTimeFormat } from "../../../services/helpers/useChangeDateFormat";
import Button from "../../../components/Button";
import { X } from "phosphor-react";

// assets
import blackCalendar from "../../../assets/icons/black-calendar.svg";
import day from "../../../assets/icons/day_icon.svg";
import time from "../../../assets/icons/blacktimeicon.svg";
import { AppContext } from "../../../context";

const ScheduledCall = () => {
  const [call, setCall] = useState<ISingleCall>();
  const { callId } = useParams();
  const navigate = useNavigate();
  const { notify } = useNotification();
  const { api } = useContext(AppContext);

  const handleCancelCall = async () => {
    const userConfirmed = window.confirm(
      "Are you sure you want to cancel this call?"
    );

    if (callId && userConfirmed && api) {
      try {
        await deleteCall(api, callId);
        navigate(-1);
        notify("Call cancelled successfully", "success");
      } catch (error: any) {
        notify(error.message, "error");
      }
    }
    return;
  };

  const getCalls = async () => {
    if (callId && api)
      try {
        const res = await getSingleCall(api, callId);
        res && setCall(res);
      } catch (error: any) {
        notify(error.message, "error");
      }
  };

  useEffect(() => {
    callId && api && getCalls();
  }, [api]);

  return (
    <>
      <ArrowLeft size={32} weight="bold" onClick={() => navigate(-1)} />

      <Header>
        <img src={blackCalendar} alt="calendar" />
        {call?.meetingName}
      </Header>

      <Section>
        <Col>
          <Subcol>
            <Prompts
              title="Select a project associated with this call"
              color="var(--GreyscaleSurfaceDefault)"
            />
            <Flashcard
              style={{
                background: "var(--Grey100)",
                border: "1px solid var(--Grey200)",
                color: "var(--GreyscaleBorderDarker)",
                fontSize: "17px",
                height: "48px",
                cursor: "pointer",
                minHeight: "43px",
                position: "relative",
                display: "flex",
                alignItems: "center",
              }}
              icon={
                <CaretDown
                  size="15"
                  weight="bold"
                  color="var(--GreyscaleTextIconBody)"
                  style={{ position: "absolute", right: "15px" }}
                />
              }
              content={call?.projectName}
            />
          </Subcol>

          <Subcol>
            <Prompts
              title="Scheduled date and time for call"
              color="var(--GreyscaleTextIconBody)"
              bold
            />
            <Text style={{ marginTop: "-10px" }}>
              You will receive a notification, both via email and within the
              app, 10 minutes before the scheduled call.
            </Text>
          </Subcol>

          <Flex style={{ marginRight: "20px" }} id="call_flex">
            <SubFlex>
              <Subcol>
                <Prompts title="Date" color="var(--GreyscaleTextIconCaption)" />
                <Flashcard
                  style={{
                    background: "var(--Grey100)",
                    border: "1px solid var(--Grey200)",
                    color: "var(--GreyscaleTextIconSubtitle)",
                    fontSize: "17px",
                    fontWeight: 600,
                    height: "48px",
                    cursor: "pointer",
                    minHeight: "43px",
                    position: "relative",
                    display: "flex",
                    alignItems: "center",
                    paddingLeft: "45px",
                  }}
                  icon={
                    <img
                      src={day}
                      style={{ position: "absolute", left: "15px" }}
                    />
                  }
                  content={call && DateTimeFormat(call?.time).formattedDate}
                  quarter
                />
              </Subcol>

              <Subcol>
                <Prompts title="Time" color="var(--GreyscaleTextIconCaption)" />
                <Flashcard
                  style={{
                    background: "var(--Grey100)",
                    border: "1px solid var(--Grey200)",
                    color: "var(--GreyscaleTextIconSubtitle)",
                    fontSize: "17px",
                    fontWeight: 600,
                    height: "48px",
                    cursor: "pointer",
                    minHeight: "43px",
                    position: "relative",
                    display: "flex",
                    alignItems: "center",
                    paddingLeft: "45px",
                  }}
                  icon={
                    <img
                      src={time}
                      style={{ position: "absolute", left: "15px" }}
                    />
                  }
                  content={call && DateTimeFormat(call?.time).formattedTime12}
                  quarter
                />
              </Subcol>
            </SubFlex>

            <Text id="to">TO</Text>

            <SubFlex>
              <Subcol>
                <Prompts title="Date" color="var(--GreyscaleTextIconCaption)" />
                <Flashcard
                  style={{
                    background: "var(--Grey100)",
                    border: "1px solid var(--Grey200)",
                    color: "var(--GreyscaleTextIconSubtitle)",
                    fontSize: "17px",
                    fontWeight: 600,
                    height: "48px",
                    cursor: "pointer",
                    minHeight: "43px",
                    position: "relative",
                    display: "flex",
                    alignItems: "center",
                    paddingLeft: "45px",
                  }}
                  icon={
                    <img
                      src={day}
                      style={{ position: "absolute", left: "15px" }}
                    />
                  }
                  content={call && DateTimeFormat(call?.time).formattedDate}
                  quarter
                />
              </Subcol>

              <Subcol>
                <Prompts title="Time" color="var(--GreyscaleTextIconCaption)" />
                <Flashcard
                  style={{
                    background: "var(--Grey100)",
                    border: "1px solid var(--Grey200)",
                    color: "var(--GreyscaleTextIconSubtitle)",
                    fontSize: "17px",
                    fontWeight: 600,
                    height: "48px",
                    cursor: "pointer",
                    minHeight: "43px",
                    position: "relative",
                    display: "flex",
                    alignItems: "center",
                    paddingLeft: "45px",
                  }}
                  icon={
                    <img
                      src={time}
                      style={{ position: "absolute", left: "15px" }}
                    />
                  }
                  content={
                    call && DateTimeFormat(call?.time).formattedTime12Plus30
                  }
                  quarter
                />
              </Subcol>
            </SubFlex>
          </Flex>

          <Subcol>
            <Prompts
              title="Project description"
              color="var(--GreyscaleSurfaceDefault)"
            />
            <Flashcard
              style={{
                background: "var(--Grey100)",
                border: "1px solid var(--Grey200)",
                color: "var(--GreyscaleTextIconSubtitle)",
                fontSize: "17px",
                height: "48px",
                cursor: "pointer",
                minHeight: "150px",
                marginBottom: "20px",
              }}
              content={call?.description}
            />
          </Subcol>
        </Col>
      </Section>

      <Button
        title="Cancel call"
        bg="var(--GreyscaleSurfaceBtBackground)"
        color="var(--WhiteSurface)"
        w="48%"
        budget
        iconRight
        icon={<X size={20} weight="bold" color="#fff" />}
        onClick={handleCancelCall}
      />
    </>
  );
};

export default ScheduledCall;
