import { useMsal } from "@azure/msal-react";
import { IApi, IQueryParams } from "../../types/apiTypes";
import { useEffect, useState } from "react";
import { loginRequest } from "../../authConfig";
import { ApiErrorResponse } from "../../types/apiResponse";
import useInterval from "../useInterval";

const useApi = (): IApi | undefined => {
  const { instance, accounts } = useMsal();
  const [apiToken, setApiToken] = useState<string>("");

  // attempt token refresh every 30 minutes,
  // note: this will trigger the login flow if silent token acquisition fails
  useInterval(() => {
    getToken();
  }, 1800000);

  useEffect(() => {
    getToken();
  }, [instance, accounts]);

  const getToken = () => {
    if (instance && accounts && accounts.length > 0) {
      const request = {
        ...loginRequest,
        account: accounts[0],
      };
      instance
        .acquireTokenSilent(request)
        .then(async (response) => {
          if (response.accessToken.length < 1) {
            setApiToken("");
          } else {
            setApiToken(response.accessToken);
          }
        })
        .catch(async (e) => {
          await instance.acquireTokenRedirect(request);
        });
    }
  };

  const handleApiError = (error: any) => {
    console.log(error);
    return new Promise((resolve, reject) => {
      if (error.name === "AbortError") {
        resolve(undefined);
      } else if (error.status === 401) {
      } else {
        reject(error);
      }
    });
  };

  const handleBadRequest = async (response: any) => {
    throw (await response.json()) as ApiErrorResponse;
  };

  const get = async (url: string) => {
    return fetch(`${process.env.REACT_APP_API}/${url}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${apiToken}`,
      },
    })
      .then(async (response) => {
        if (response.status === 400) {
          await handleBadRequest(response);
        } else if (!response.ok) {
          handleApiError(response);
        }
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
          return response.json();
        }
      })
      .catch(handleApiError);
  };

  const getPage = async (url: string, query: IQueryParams) => {
    url += `?pageSize=${query.pageSize}&pageNumber=${query.pageNumber}`;
    if (query.search) {
      url += `&search=${query.search}`;
    }
    if (query.sortBy) {
      url += `&sortBy=${query.sortBy}&sortOrder=${query.sortOrder}`;
    }
    if (query.filterBy) {
      url += `&filterBy=${query.filterBy}`;
    }
    return get(url);
  };

  const post = async (url: string, payload?: any) => {
    return fetch(`${process.env.REACT_APP_API}/${url}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${apiToken}`,
      },
      body: payload ? JSON.stringify(payload) : null,
    })
      .then(async (response) => {
        if (response.status === 400) {
          await handleBadRequest(response);
        } else if (!response.ok) {
          handleApiError(response);
        }
        if (response) {
          const contentType = response.headers.get("content-type");
          if (contentType && contentType.indexOf("application/json") !== -1) {
            return response.json();
          }
        }
      })
      .catch(handleApiError);
  };

  const put = async (url: string, payload?: any) => {
    return fetch(`${process.env.REACT_APP_API}/${url}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${apiToken}`,
      },
      body: payload ? JSON.stringify(payload) : null,
    })
      .then(async (response) => {
        if (response.status === 400) {
          await handleBadRequest(response);
        } else if (!response.ok) {
          handleApiError(response);
        }

        if (response) {
          const contentType = response.headers.get("content-type");
          if (contentType && contentType.indexOf("application/json") !== -1) {
            return response.json();
          }
        }
      })
      .catch(handleApiError);
  };

  const deleteData = async (url: string) => {
    return fetch(`${process.env.REACT_APP_API}/${url}`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        ContentType: "application/json",
        Authorization: `Bearer ${apiToken}`,
      },
    })
      .then(async (response) => {
        if (response.status === 400) {
          await handleBadRequest(response);
        } else if (!response.ok) {
          handleApiError(response);
        }

        if (response) {
          const contentType = response.headers.get("content-type");
          if (contentType && contentType.indexOf("application/json") !== -1) {
            return response.json();
          }
        }
      })
      .catch(handleApiError);
  };

  const putData = async (url: string, payload?: any) => {
    const formData = new FormData();

    for (const name in payload) {
      formData.append(name, payload[name]);
    }

    return fetch(`${process.env.REACT_APP_API}/${url}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${apiToken}`,
      },
      body: formData,
    })
      .then(async (response) => {
        if (response.status === 400) {
          await handleBadRequest(response);
        } else if (!response.ok) {
          handleApiError(response);
        }

        if (response) {
          const contentType = response.headers.get("content-type");
          if (contentType && contentType.indexOf("application/json") !== -1) {
            return response.json();
          }
        }
      })
      .catch(handleApiError);
  };

  const api: IApi = {
    get,
    post,
    put,
    putData,
    getPage,
    deleteData,
    token: apiToken,
  };
  return apiToken ? api : undefined;
};
export default useApi;
