import React from "react";
import {
    StepsContainer,
    Steps,
    Substeps,
    Circle
} from "./style"
// assets
import activeArrow from "../../assets/icons/active-arrow.svg";
import inactiveArrow from "../../assets/icons/inactive-arrow.svg";
import doneArrow from "../../assets/icons/done-arrow.svg";


interface StepperProps {
    index: number
}

const tabContent = [
    { id: 1, content: "Create Project" },
    { id: 2, content: "Budget Approval" },
    { id: 3, content: "Pending Payment" },
    { id: 4, content: "Development" },
];
  
const Stepper = ({ index }: StepperProps) => {
    const getBackgroundColor = (currentIndex: number) => {
        if (currentIndex === index) {
          return "#FFC24C";
        } else if (currentIndex < index) {
          return "#07AF22";
        } else {
          return "#E2E4E2";
        }
    };
    
    const getArrow = (currentIndex: number) => {
        if (currentIndex === index) {
          return activeArrow;
        } else if (currentIndex < index) {
          return doneArrow;
        } else {
          return inactiveArrow;
        }
    };
    
    const getColor = (currentIndex: number) => {
        return currentIndex <= index ? "#ffffff" : "#6A7368";
    };

    return (
        <>
            <StepsContainer>
                {tabContent.map((item) => (
                    <React.Fragment key={item.id}>
                    <Steps>
                        <Substeps className={item.id === 4 ? "substep4" : ""}>
                        <Circle
                            style={{
                            backgroundColor: getBackgroundColor(item.id),
                            color: getColor(item.id),
                            }}
                        >
                            {item.id}
                        </Circle>
                        <div
                            style={{
                            color: index === item.id ? "#222522" : "#6A7368",
                            }}
                        >
                            {item.content}
                        </div>
                        </Substeps>
                    </Steps>
                    {item.id !== 4 && <img src={getArrow(item.id)} alt="arrows" />}
                    </React.Fragment>
                ))}
            </StepsContainer>
        </>
    )
}

export default Stepper