import styled from '@emotion/styled'
import { ArrowRight } from 'phosphor-react';
import { useNavigate } from 'react-router-dom';
import Button from '../../../components/Button';
import { 
  Wrapper,
  Container,
  Flex,
  Col,
  Spacer,
  Header,
  Text1,
  SubHeader,
  Text2,
  Text3 
} from './style';

// assets
import confirmOrder from "../../../assets/images/successful.svg";

const DevelopmentTab = () => {
  const navigate = useNavigate();

  return (
    <Wrapper>
      <Container>
        <img src={confirmOrder} alt="confirm" />
        <Header>Payment summary</Header>
        <Text1>
          Your transaction was successful, you will get a receipt in your email
        </Text1>

        <SubHeader>Transaction summary</SubHeader>

        <Flex>
          <Col>
            <Text2>Date:</Text2>
            <Text2>Amount paid:</Text2>
            <Text2>Reference ID:</Text2>
            <Text2>Transaction type:</Text2>
            <Text2>Payment method:</Text2>
            <Text2>Payment to:</Text2>
          </Col>

          <Col className='flex-end'>
            <Text3>29 - 10 - 2023</Text3>
            <Text3>$1000</Text3>
            <Text3>12hbvsiyavsdcv</Text3>
            <Text3>Deposit / Downpayment</Text3>
            <Text3>Card transaction</Text3>
            <Text3>Algocipher holding account</Text3>
          </Col>
        </Flex>

        <Spacer>
          <Button 
            title='Continue to project'
            bg="var(--PrimaryBorderLighter)"
            color='#FFF'
            iconRight
            icon={<ArrowRight size={25} weight="thin" color='#FFF' />}
            onClick={()=>{
              navigate("/projects")
            }}
          />
          {/* Remember to set the status of the project to Development immediately the user clicks on continue to project */}
        </Spacer>
      </Container>
    </Wrapper>
  )
}

export default DevelopmentTab
