import { AppState, IContextProviderProps } from "../types";
import { useEffect, useReducer, useState } from "react";
import { AppContext } from "..";
import { msalConfig } from "../../authConfig";
import { PublicClientApplication } from "@azure/msal-browser";
import { IUser } from "../../types/user";
import useApi from "../../hooks/api/useApi";
import LoadingScreen from "../../components/Loading/Screen";
import { getMe } from "../../services/api/userApi";
import { getUserNotificationCount } from "../../services/api/notificationApi";
import { getLookup } from "../../services/api/lookupApi";

const AppReducer: React.Reducer<AppState, Partial<AppState>> = (
  state,
  action
) => {
  return { ...state, ...action };
};

let count = 0;
const AppContextProvider = ({ children }: IContextProviderProps) => {
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const api = useApi();
  const [isLoading, setIsLoading] = useState(true);
  const [notificationCount, setNotificationCount] = useState(0);

  const setUser = (user: IUser) => {
    if (user) {
      const newState: Partial<AppState> = {
        ...state,
        user,
      };
      dispatch(newState);
    }
  };

  const initialState: AppState = {
    msalInstance: new PublicClientApplication(msalConfig),
    setUser: setUser,
    isSidebarOpen,
    setSidebarOpen,
    notificationCount: 0,
    setNotificationCount: setNotificationCount,
    api: api,
  };

  const [state, dispatch] = useReducer(AppReducer, initialState);

  useEffect(() => {
    if (api && !count) {
      if (!state.lookup) {
        getLookup(api).then((lookup) => {
          if (lookup) {
            const newState: Partial<AppState> = {
              ...state,
              lookup: lookup,
            };
            dispatch(newState);
            setIsLoading(false);
          }
        });
      }
      getUserNotificationCount(api).then((count) => {
        const newState: Partial<AppState> = {
          ...state,
          notificationCount: count,
        };
        dispatch(newState);
      });
      if (state.user === undefined) {
        getMe(api).then((user: IUser) => {
          if (user) {
            const newState: Partial<AppState> = {
              ...state,
              user,
              api: api,
            };
            dispatch(newState);
            setIsLoading(false);
          }
        });
      }
      count++;
    }
  }, [api]);

  useEffect(() => {
    const newState: Partial<AppState> = {
      ...state,
      isSidebarOpen,
    };
    dispatch(newState);
  }, [isSidebarOpen]);

  useEffect(() => {
    const newState: Partial<AppState> = {
      ...state,
      notificationCount,
    };
    dispatch(newState);
  }, [notificationCount]);

  return isLoading ? (
    <LoadingScreen />
  ) : (
    <AppContext.Provider value={state}>{children}</AppContext.Provider>
  );
};
export default AppContextProvider;
