import { Button, Typography } from "@mui/material";
import { CSSProperties, ReactNode, useRef, useState, useEffect } from "react";
import { FilePickerStyle, FileTypeModalItem } from "./style";

export interface IFileType {
  name: string;
  icon: string;
  mediaTypes: string;
}
export interface IFilePickerProps {
  allowedTypes: IFileType[];
  onSelected: (file: File) => void;
  disabled: boolean;
  loader?: ReactNode;
  showLoader?: boolean;
  buttonStyle?: CSSProperties;
  buttonImg?: string;
  flexDirection?: "column" | "column-reverse";
  top?: string;
  bottom?: string;
  left?: string;
}

const FilePicker = ({
  allowedTypes,
  onSelected,
  disabled,
  loader,
  showLoader,
  buttonImg,
  buttonStyle,
  top,
  bottom,
  left,
  flexDirection,
}: IFilePickerProps) => {
  const [showFileTypes, setShowFileTypes] = useState(false);
  const inputFileRef = useRef<HTMLInputElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = (event: any) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setShowFileTypes(false);
    }
  };

  const handleFileTypeSelected = (fileType: IFileType) => {
    if (inputFileRef?.current) {
      inputFileRef.current.accept = fileType.mediaTypes;
      inputFileRef.current.click();
    }
  };

  const handleFileSelected = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e?.currentTarget?.files?.[0];
    if (file) {
      onSelected(file);
      setShowFileTypes(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <FilePickerStyle ref={wrapperRef} style={{ flexDirection: flexDirection }}>
      <Button
        disabled={disabled}
        onClick={() => setShowFileTypes((prev) => !prev)}
        id="uploadButton"
        component="label"
        style={buttonStyle}
      >
        <img src={buttonImg} />
      </Button>
      <div
        style={{
          display: showFileTypes ? "block" : "none",
          top: top,
          bottom: bottom,
          left: left,
        }}
        className="file-types"
      >
        <input
          type="file"
          ref={inputFileRef}
          style={{ display: "none" }}
          onChange={handleFileSelected}
        />
        {allowedTypes.map((item) => (
          <FileTypeModalItem onClick={() => handleFileTypeSelected(item)}>
            <img src={item.icon} /> {item.name}
          </FileTypeModalItem>
        ))}
      </div>
      {showLoader && loader}
    </FilePickerStyle>
  );
};

export default FilePicker;
