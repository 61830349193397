import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Pagination,
  Typography,
} from "@mui/material";
import {
  ChangeEventHandler,
  ReactElement,
  useEffect,
  useMemo,
  useState,
} from "react";
import ShortMagnifier from "../../../assets/icons/short-magnifier.svg";
import DownCaret from "../../../assets/icons/down-caret.svg";
import { CaretDoubleDown, CaretDown, CaretUp } from "phosphor-react";
import {
  EmptyContent,
  PaginationStyle,
  Search,
  TableContainer,
  TableDesign,
  TableNavigationBar,
  TableWrapper,
  Text,
} from "./style";
import useDebounce from "../../../hooks/useDebounce";

export interface ICustomTableColumn {
  id: string;
  text: string;
  canSort?: boolean;
}

interface ICustomTableProps {
  columns: ICustomTableColumn[];
  data: any;
  filters?: string[];
  pageSize: number;
  pageNumber: number;
  totalRecords: number;
  isLoading?: boolean;
  emptyContent?: ReactElement;
  onPageChange: (pageNumber: number, pageSize: number) => void;
  onSort?: (sortBy: string, order: string) => void;
  onFilter?: (filterBy: string) => void;
  onSearch?: (query: string) => void;
  onRowClick?: (id: string) => void;
}

const CustomTable = ({
  columns,
  data,
  filters,
  pageSize,
  pageNumber,
  totalRecords,
  isLoading,
  emptyContent,
  onPageChange,
  onSort,
  onFilter,
  onSearch,
  onRowClick,
}: ICustomTableProps) => {
  const [filerBy, setFilterBy] = useState(filters?.[0]);

  const [sortBy, setSortBy] = useState<ICustomTableColumn>();
  const [sortDesc, setSortDesc] = useState(false);
  const [paginationDropDown, setPaginationDropDown] = useState(false);
  const { value: search, setValue: setSearch } = useDebounce({ delayMS: 1000 });

  const skeletonScreenTemplates = [0, 1, 2];

  useEffect(() => {
    if (onSearch) {
      const searchParam = search ?? " ";
      onSearch(searchParam);
    }
  }, [search]);

  const handleFilterChanged = (filter: string) => {
    setFilterBy(filter);
    if (onFilter) {
      onFilter(filter);
    }
  };

  const handleColumnClicked = (column: ICustomTableColumn) => {
    if (!column.canSort) return;
    setSortBy(column);
    setSortDesc((prev) => !prev);

    if (onSort && sortBy) {
      onSort(sortBy.id, sortDesc ? "desc" : "asc");
    }
  };

  const handleCaretStyle = () => ({
    transform: paginationDropDown ? "rotate(180deg)" : "rotate(0deg)",
    transition: "transform 0.3s ease",
  });

  const totalPages = useMemo(() => {
    const total = Math.ceil(totalRecords / pageSize);
    return total <= 0 ? 1 : total;
  }, [totalRecords, pageSize]);

  const isEmpty = () => !isLoading && data.length === 0 && !search;

  return (
    <>
      <TableDesign>
        <TableNavigationBar>
          {filters && (
            <div className="first-block">
              {filters.map((item) => (
                <button
                  className={filerBy === item ? "active" : ""}
                  onClick={() => {
                    handleFilterChanged(item);
                  }}
                >
                  {item}
                </button>
              ))}
            </div>
          )}
          <div className="second-block">
            <div className="sub-second-block">
              <Search
                onChange={(e) => setSearch(e.target.value)}
                placeholder="Search"
              ></Search>
              <img src={ShortMagnifier} />
            </div>
          </div>
        </TableNavigationBar>
        {isEmpty() ? (
          <EmptyContent className="emtpty-content">{emptyContent}</EmptyContent>
        ) : (
          <TableWrapper>
            <TableContainer>
              <Table sx={{ overflow: "auto" }}>
                <TableHead>
                  <TableRow
                    sx={{
                      background: "#EFF0EF",
                      height: "45px",
                      padding: " 12px 32px 12px 32px",
                    }}
                  >
                    {columns.map((item) => (
                      <TableCell
                        key={item.id}
                        sx={{ pl: "40px", cursor: "pointer" }}
                        onClick={() => {
                          handleColumnClicked(item);
                        }}
                      >
                        <div className="column-sub-container">
                          <Text>{item.text}</Text>
                          <div
                            className={sortBy === item ? "active" : ""}
                            id="isSort"
                          >
                            {sortDesc ? <CaretUp /> : <CaretDown />}
                          </div>
                        </div>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>

                {isLoading ? (
                  <TableBody>
                    {skeletonScreenTemplates.map((item: any, index: any) => {
                      return (
                        <TableRow key={index} className="skeleton-table-row">
                          <Typography className="skeleton-table-row-light"></Typography>
                          {skeletonScreenTemplates.map((column) => (
                            <TableCell>
                              <Typography sx={{ height: "20px" }}></Typography>
                            </TableCell>
                          ))}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                ) : (
                  <TableBody>
                    {data.map((item: any, index: any) => {
                      return (
                        <TableRow
                          key={index}
                          sx={{ cursor: "pointer" }}
                          onClick={() => onRowClick && onRowClick(item.id)}
                        >
                          {columns.map((column, index) => (
                            <TableCell sx={{ pl: "40px", paddingRight: columns.length - 1 === index ? "40px" : "" }} key={column.id}>
                              {item[column.id]}
                            </TableCell>
                          ))}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </TableWrapper>
        )}
      </TableDesign>

      {totalRecords > 0 && (
        <PaginationStyle>
          <ul className="page-size">
            <p
              className="p"
              onClick={() => setPaginationDropDown((prev) => !prev)}
            >
              {pageSize}
              <div>
                <img src={DownCaret} style={handleCaretStyle()} />
              </div>
            </p>
            {[10, 20, 30, 50].map((size) => (
              <li
                key={size}
                style={{
                  display: paginationDropDown ? "block" : "none",
                }}
                onClick={() => onPageChange(pageNumber, size)}
              >
                {size}
              </li>
            ))}
          </ul>
          <Pagination
            className="pagination"
            count={totalPages}
            page={pageNumber}
            onChange={(e, page) => onPageChange(page, pageSize)}
          />
        </PaginationStyle>
      )}
    </>
  );
};

export default CustomTable;
