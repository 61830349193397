import React, { useContext } from "react";
import styled from "@emotion/styled";
import Prompts from "../Prompts";
import CustomInput from "../Input";

// assets
import algocipherBudgetIcon from "../../assets/icons/algocipher-budget.svg";
import userBudgetIcon from "../../assets/icons/user-budget.svg";
import CustomSelect from "../Select";
import { AppContext } from "../../context";

interface CounterProposalsProps {
  icon?: "user" | "algocipher";
  text?: string;
  subText?: string;
  budget: any;
  timeline: any;
  setBudget?: any;
  setTimeline?: any;
}

const Wrapper = styled.section`
  margin: 30px auto;
`;
const Section = styled.section`
  display: flex;
  align-items: center;
  column-gap: 15px;
`;
const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const Spacer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 991px) {
    flex-direction: column;
    row-gap: 10px;
  }
`;
const VStack = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 5px;
`;
const Text = styled.div`
  font-size: 17px;
  font-weight: 500;
  line-height: 150%;
  color: var(--GreyscaleTextIconBody);
`;
const Subtext = styled.div`
  font-size: 14px;
  line-height: 150%;
  color: var(--GreyscaleBorderDarker);
`;

const CounterProposals = ({
  icon,
  text,
  subText,
  budget,
  setBudget,
  setTimeline,
  timeline,
}: CounterProposalsProps) => {
  const { lookup } = useContext(AppContext);

  return (
    <Wrapper>
      <Flex>
        <Section>
          <img
            src={icon === "user" ? userBudgetIcon : algocipherBudgetIcon}
            alt="icon"
          />
          <VStack>
            <Text>{text}</Text>
            <Subtext>{subText}</Subtext>
          </VStack>
        </Section>
      </Flex>
      <div>
        <Text style={{ fontSize: "14px", margin: "20px auto 10px" }}>
          Negotiable budget
        </Text>
        <VStack>
          <Prompts title="Cost" color="var(--Grey500)" />
          <CustomInput
            placeholder="500"
            value={budget.cost}
            type="text"
            icon="$"
            style={{
              padding: "10px 16px 10px 40px",
            }}
            iconPosition={{ position: "absolute", left: "20px" }}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setBudget({ ...budget, cost: e.target.value })
            }
          />
        </VStack>
      </div>
      <div>
        <Text style={{ fontSize: "14px", margin: "20px auto 10px" }}>
          Estimated period
        </Text>
        <Spacer className="flex-budget">
          <VStack>
            <Prompts title="Timeline" color="var(--Grey500)" />
            <CustomSelect
              value={timeline}
              size="size"
              name="timeline"
              handleChange={(e) => setTimeline(e.target.value)}
              data={
                lookup?.ProjectTimeline.map((item) => {
                  return { title: item.description, value: item.id };
                }) ?? []
              }
            />
          </VStack>
        </Spacer>
      </div>
    </Wrapper>
  );
};

export default CounterProposals;
