import { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { getSingleProject } from '../../../services/api/projectApi';
import { IGetProject } from '../../../types/project';
import useNotification from '../../../hooks/useNotification';
import BudgetApprovalTab from '../budget-approval/budget-approval-tab';
import PendingPaymentTab from '../pending-payment/pending-payment-tab';
import DevelopmentTab from '../development/development-tab';
import { AppContext } from '../../../context';
import Stepper from '../../../components/Stepper';

const ProjectId = () => {
  const { api } = useContext(AppContext);
  const [index, setIndex] = useState(2);
  const [selectedProject, setSelectedProject] = useState<IGetProject | null>(
    null
  );
  const [isLoading, setIsLoading] = useState(false);
  const { notify } = useNotification();
  const { projectId } = useParams();

  const getLocation = () => {
    if (!selectedProject) return;

    const { status } = selectedProject;

    if (status === "inreview") {
      setIndex(2);
    } else if (status === "pending") {
      setIndex(3);
    } else if (status === "development") {
      setIndex(4);
    } else return;
  };

  const getProjectDetails = async () => {
    if (projectId && api)
      try {
        const res = await getSingleProject({ api, projectId });
        if (res) {
          setSelectedProject(res);
        }
      } catch (error: any) {
        notify(error.message, "error");
      }
  };

  useEffect(() => {
    if (api) {
      getProjectDetails();
    }
  }, [api]);

  useEffect(() => {
    getLocation();
  }, [selectedProject]);

  return (
    <section style={{ height: "100%" }}>
      <>
        <Stepper index={index} />

        {index === 2 && selectedProject && (
          <BudgetApprovalTab 
            selectedProject={selectedProject} 
            setIndex={setIndex}
          />
        )}

        {index === 3 && selectedProject && (
          <PendingPaymentTab 
            selectedProject={selectedProject}
            setIndex={setIndex} 
          />
        )}
            
        {index === 4 && <DevelopmentTab />}
      </>
    </section>
  );
};

export default ProjectId;
