import { ApiResponse } from "../../types/apiResponse";
import { IApi, IQueryParams } from "../../types/apiTypes";
import {
  IAcceptProposalPayload,
  ICounterProposal,
  ICreateProject,
  IGetProject,
  IProject,
  IProjectId,
  projectResultProps,
} from "../../types/project";

interface getSingleProjectProps {
  api: IApi;
  projectId: string;
}

export const createProject = (
  api: IApi,
  payload: ICreateProject
): Promise<projectResultProps> => {
  const url = "project";
  return api
    .post(url, payload)
    .then((result: ApiResponse<projectResultProps>) => {
      return result.data;
    })
    .catch((error) => {
      return new Promise((resolve, reject) => {
        reject(error);
      });
    });
};

export const getAllProjects = (
  api: IApi,
  query: IQueryParams
): Promise<IProject> => {
  const url = "project";
  return api
    .getPage(url, query)
    .then((result: ApiResponse<IProject>) => {
      return result.data;
    })
    .catch((error) => {
      return new Promise((resolve, reject) => {
        reject(error);
      });
    });
};

export const getSingleProject = ({
  api,
  projectId,
}: getSingleProjectProps): Promise<IGetProject> => {
  const url = `project/${projectId}`;
  return api
    .get(url)
    .then((result: ApiResponse<IGetProject>) => {
      return result.data;
    })
    .catch((error) => {
      return new Promise((resolve, reject) => {
        reject(error);
      });
    });
};

export const getUserProjectIds = (api: IApi): Promise<IProjectId[]> => {
  const url = "project/ids";
  return api
    .get(url)
    .then((result: ApiResponse<IProjectId[]>) => {
      return result.data;
    })
    .catch((error) => {
      return new Promise((resolve, reject) => {
        reject(error);
      });
    });
};

export const cancelProject = (
  api: IApi,
  projectId: string
): Promise<boolean> => {
  const url = `project/${projectId}/cancel`;
  return api
    .post(url)
    .then((result: ApiResponse<boolean>) => {
      return result.data;
    })
    .catch((error) => {
      return new Promise((resolve, reject) => {
        reject(error);
      });
    });
};

export const acceptProposal = (
  api: IApi,
  projectId: string,
  payload: IAcceptProposalPayload
): Promise<boolean> => {
  const url = `project/${projectId}/accept-proposal`;
  return api
    .post(url, payload)
    .then((result: ApiResponse<boolean>) => {
      return result.data;
    })
    .catch((error) => {
      return new Promise((resolve, reject) => {
        reject(error);
      });
    });
};

export const counterProposal = (
  api: IApi,
  projectId: string,
  payload: ICounterProposal
): Promise<boolean> => {
  const url = `project/${projectId}/counter-proposal`;
  return api
    .post(url, payload)
    .then((result: ApiResponse<boolean>) => {
      return result.data;
    })
    .catch((error) => {
      return new Promise((resolve, reject) => {
        reject(error);
      });
    });
};

export const makePayment = (api: IApi, projectId: string): Promise<boolean> => {
  const url = `project/${projectId}/payment`;
  return api
    .post(url)
    .then((result: ApiResponse<boolean>) => {
      return result.data;
    })
    .catch((error) => {
      return new Promise((resolve, reject) => {
        reject(error);
      });
    });
};
