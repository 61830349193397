import { useContext, useEffect, useState } from "react";
import Button from "../../components/Button";
import { Plus } from "phosphor-react";
import useNotification from "../../hooks/useNotification";
import {
  changeDateFormatFromISO,
  changeDateFormatToTimelineFormat,
} from "../../services/helpers/useChangeDateFormat";
import {
  getAllProjects,
  getSingleProject,
} from "../../services/api/projectApi";
import { IGetProject, projectResultProps } from "../../types/project";
import {
  Flex,
  FlexTable,
  Empty,
  EmptyContent,
  Text,
  TContainer,
  Timeline,
} from "./style";
import CustomTable from "../../components/Shared/CustomTable";
import { Avatar, Typography } from "@mui/material";
import { getStatusColors } from "../../services/helpers/helpers";
import { useNavigate } from "react-router-dom";

// assets
import emptyCart from "../../assets/icons/empty-cart.svg";
import projectIcon from "../../assets/images/projects_name.svg";
import { AppContext } from "../../context";
import {
  Construction,
  Pending,
  TaskAltRounded,
  Visibility,
} from "@mui/icons-material";

const tabContent = [
  { id: 1, content: "Create Project" },
  { id: 2, content: "Budget Approval" },
  { id: 3, content: "Pending Payment" },
  { id: 4, content: "Development" },
];
const tableColumnStyle = {
  fontFamily: "jost",
  fontWeight: "400",
  fontSize: "17px",
  lineHeight: "25.5px",
  color: "#222522",
  whiteSpace: "nowrap",
  overflow: "hidden",
  // width: "150px",
  textOverflow: "ellipsis",
  display: "flex",
  alignItems: "center",
  gap: "10px",
};
const dateStyle = {
  fontWeight: "400",
  fontSize: "14px",
  lineHeight: "21px",
  color: "var(--GreyscaleSurfaceDisabled)",
};
const statusColumnStyle = {
  ...tableColumnStyle,
  width: "fit-content",
  padding: "0px 8px",
  borderRadius: "100px",
};
const columns = [
  {
    id: "name",
    text: "Name",
    canSort: true,
  },
  {
    id: "category",
    text: "Category",
    canSort: true,
  },
  {
    id: "platform",
    text: "Platform",
    canSort: true,
  },
  {
    id: "language",
    text: "Language",
    canSort: true,
  },
  {
    id: "created",
    text: "Submitted At",
    canSort: true,
  },
  {
    id: "status",
    text: "Status",
    canSort: true,
  },
  {
    id: "timeline",
    text: "Timeline",
    canSort: true,
  },
];
export const getStatusIcon = (status: string, color: string) => {
  if (status === "inreview") {
    return <Visibility sx={{ color: color }} />;
  } else if (status === "pending") {
    return <Pending sx={{ color: color }} />;
  } else if (status === "develop") {
    return <Construction sx={{ color: color }} />;
  } else {
    return <TaskAltRounded sx={{ color: color }} />;
  }
};

const Projects = () => {
  const [projects, setProjects] = useState<projectResultProps[]>([]);
  const { notify } = useNotification();
  const [queryParams, setQueryParams] = useState({
    pageNumber: 1,
    pageSize: 10,
    sortBy: "",
    filterBy: "",
    sortOrder: "asc",
    inputValue: "",
  });
  const [totalRecords, setTotalRecords] = useState(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const { api, lookup } = useContext(AppContext);

  const handlePageChanged = (page: number, size: number) => {
    const newQuery = { ...queryParams, pageNumber: page, pageSize: size };
    setQueryParams(newQuery);
  };

  const handleSortChanged = (sortBy: string, sortOrder: string) => {
    const newQuery = {
      ...queryParams,
      pageNumber: 1,
      sortBy: sortBy,
      sortOrder: sortOrder,
    };
    setQueryParams(newQuery);
  };

  const handleSearch = (searchValue: string) => {
    const newQuery = {
      ...queryParams,
      search: searchValue,
    };
    setQueryParams(newQuery);
  };

  const getPlatform = (key: string) => {
    return (
      lookup?.ProjectSolutionPlatform.find((item) => item.key == key)
        ?.description ?? ""
    );
  };
  const getCategory = (key: string) => {
    return (
      lookup?.ProjectCategory.find((item) => item.key == key)?.description ?? ""
    );
  };
  const getLanguage = (key: string) => {
    return (
      lookup?.ProjectSolutionLanguage.find((item) => item.key == key)
        ?.description ?? ""
    );
  };
  const getTimeline = (key: string) => {
    return (
      lookup?.ProjectTimeline.find((item) => item.key == key)?.description ?? ""
    );
  };

  const tableData = projects.map((item) => {
    const status = getStatusColors(item.status);
    const created = changeDateFormatFromISO(
      new Date(item.created).toISOString()
    );
    return {
      id: item.projectId,
      name: (
        <FlexTable>
          <div
            style={{
              width: "40px",
              height: "40px",
              borderRadius: "100%",
              backgroundColor: status.bgColor,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {getStatusIcon(item.status, status.color)}
          </div>
          <div>
            <Typography sx={{ ...tableColumnStyle, fontWeight: 500 }}>
              {item.name}
            </Typography>
            <Typography sx={dateStyle}>{getPlatform(item.platform)}</Typography>
          </div>
        </FlexTable>
      ),
      category: (
        <Typography sx={tableColumnStyle}>
          {getCategory(item.category)}
        </Typography>
      ),
      status: (
        <Typography
          sx={statusColumnStyle}
          style={{
            background: getStatusColors(item.status).bgColor,
            color: getStatusColors(item.status).color,
          }}
        >
          {getStatusColors(item.status).status}
        </Typography>
      ),
      platform: (
        <Typography sx={tableColumnStyle}>
          {getPlatform(item.platform)}
        </Typography>
      ),
      language: (
        <Typography sx={tableColumnStyle}>
          {getLanguage(item.language)}
        </Typography>
      ),
      created: <Typography sx={tableColumnStyle}>{created}</Typography>,
      timeline: (
        <Typography sx={tableColumnStyle}>
          {getTimeline(item.timeline)}
        </Typography>
      ),
    };
  });

  const fetchProjectsData = async () => {
    if (api) {
      try {
        setIsLoading(true);
        const res = await getAllProjects(api, queryParams);
        if (res) {
          setProjects(res.result);
          setTotalRecords(res.total);
        }
      } catch (error: any) {
        notify(
          "Error fetching projects, Please try again or contact support if issue persists",
          "error"
        );
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleSwitchToBudgetApproval = (projectId: string) => {
    navigate(`/projects/${projectId}`);
  };

  useEffect(() => {
    fetchProjectsData();
  }, [api, queryParams]);

  const emptyContent = (
    <EmptyContent>
      <img src={emptyCart} alt="empty-cart" />
      <Text style={{ fontWeight: 500 }}>
        It looks a little empty in here, create a new project
      </Text>
      <Button
        title="Create Project"
        bg="var(--PrimaryBorderLighter)"
        color="#FFF"
        onClick={() => navigate("/projects/create-project")}
        icon={<Plus color="#FFF" weight="bold" size={20} />}
      />
    </EmptyContent>
  );

  return (
    <div>
      <Flex>
        <Button
          title="Create Project"
          bg="var(--PrimaryBorderLighter)"
          color="var(--GreyscaleSurfaceSubtle)"
          onClick={() => navigate("/projects/create-project")}
          icon={
            <Plus
              color="var(--GreyscaleSurfaceSubtle)"
              weight="bold"
              size={20}
            />
          }
        />
      </Flex>
      <CustomTable
        columns={columns}
        data={tableData}
        pageSize={queryParams.pageSize}
        pageNumber={queryParams.pageNumber}
        totalRecords={totalRecords}
        onPageChange={handlePageChanged}
        onSort={handleSortChanged}
        onSearch={handleSearch}
        onRowClick={handleSwitchToBudgetApproval}
        isLoading={isLoading}
        emptyContent={emptyContent}
      />
    </div>
  );
};

export default Projects;
