const months = [
  'January', 'February', 'March', 'April', 'May', 'June',
  'July', 'August', 'September', 'October', 'November', 'December'
];

// This takes the inputDate in the form of yyyy-mm-dd and returns date in the form e.g., Wednesday, 28th February 2024
export function changeDateFormat(inputDate: any) {
  const dateParts = inputDate.split('-');
  const year = dateParts[0];
  const month = months[parseInt(dateParts[1], 10) - 1];
  const day = parseInt(dateParts[2], 10);
  
  // Function to add ordinal suffix to the day
  const addOrdinalSuffix = (num: any) => {
    const suffixes = ['th', 'st', 'nd', 'rd'];
    const v = num % 100;
    return num + (suffixes[(v - 20) % 10] || suffixes[v] || suffixes[0]);
  };
  
  const formattedDate = `${addOrdinalSuffix(day)} ${month} ${year}`;
  
  return formattedDate;
}

// This takes the inputDate in the ISO form 2024-02-27T14:33:15.877Z and returns date in the form dd mm yy e.g., 27 February 2024
export const changeDateFormatFromISO = (inputDate: any) => {
  const date = new Date(inputDate);
  const day = date.getDate();
  const monthIndex = date.getMonth();
  const year = date.getFullYear(); 

  const formattedDay = day < 10 ? `0${day}` : day;
  
  const formattedDate =  `${formattedDay} ${months[monthIndex]} ${year}`;

  return formattedDate
}

// This takes the inputDate in the ISO form 2024-02-27T14:33:15.877Z and returns date in the form yy-mm-dd e.g., 24-02-27
export const changeDateFormatToTimelineFormat = (inputDate: any) => {
  const date = new Date(inputDate);
  const formattedDate = date.toLocaleDateString('en-GB', {
    year: '2-digit',
    month: '2-digit',
    day: '2-digit',
  });

  return formattedDate
}

// This takes the inputDate in the form yy-mm-dd e.g., 24-02-27 and returns ISO form 2024-02-27T14:33:15.877Z
export const changeTimelineFormatToISO = (inputDate: any) => {
  // Split the input date string into its components
  const [year, month, day] = inputDate.split('-');

  // Create a new Date object using the parsed components
  // Note: Since the input format is 'yy-mm-dd', adjust the year component appropriately
  const fullYear = parseInt(year, 10) + 2000; // assuming 21st century for 'yy' format
  const date = new Date(fullYear, parseInt(month, 10) - 1, parseInt(day, 10));

  // Return the date as an ISO string
  return date.toISOString();
};

// This is used to get the correct date format in the form yyyy-mm-dd from a date string in the form Fri May 24 2024 00:00:00 GMT+0100 (West Africa Standard Time)
export const changeDateFormatFromString = (dateString: string) => {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};

// This is used to get the correct time format in am or pm from an isoString in the form 2024-02-27T14:33:15.877Z
export const getTimeFromISO = (isoString: string) => {
  const date = new Date(isoString);
  let hours = date.getHours();
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const ampm = hours >= 12 ? 'pm' : 'am';
  
  hours = hours % 12;
  hours = hours ? hours : 12; 
  
  return `${hours}:${minutes} ${ampm}`;
};

// This takes the input date in isoString format and returns the date in dd/mm/yy format, time in 24 hr format and 12 hr format
export const  DateTimeFormat = (isoString: string) => {
  const date = new Date(isoString);

  // Format the date in dd/mm/yy format
  const formattedDate = date.toLocaleDateString('en-GB', {
    day: '2-digit',
    month: '2-digit',
    year: '2-digit'
  });

  // Format the time in 24-hour format (HH:mm)
  const formattedTime = date.toLocaleTimeString('en-GB', {
    hour: '2-digit',
    minute: '2-digit',
    hour12: false
  });

  // format time in 12-hour format
  const formattedTime12 = date.toLocaleTimeString('en-GB', {
    hour: '2-digit',
    minute: '2-digit',
    hour12: true
  });

  const datePlus30Minutes = new Date(date.getTime() + Number(process.env.REACT_APP_CALL_DURATION) * 60000);

  // Format the new date object in 12-hour format
  const formattedTime12Plus30 = datePlus30Minutes.toLocaleTimeString('en-GB', {
    hour: '2-digit',
    minute: '2-digit',
    hour12: true
  });

  return {
    formattedDate, formattedTime, formattedTime12, formattedTime12Plus30
  }
}