import styled from '@emotion/styled';
import { CircularProgress } from '@mui/material';

interface ButtonProps {
  title: string;
  bg: string;
  color: string;
  icon?: any;
  onClick?: any;
  iconRight?: boolean
  w?: string;
  budget?: boolean;
  isLoading?: boolean;
  disabled?: boolean
}

const Btn = styled.div<ButtonProps>`
  display: flex;
  align-items: center;
  cursor: pointer;
  column-gap: 8px;
  padding: 10px 25px;
  border-radius: 100px;
  position: relative;
  width: ${(props) => props.w && props.w};
  background-color: ${(props) => props.bg && props.bg};
  opacity: ${(props) => props.disabled ? 0.2 : 1};

  @media (min-width: 992px) and (max-width: 1300px) {
    padding: 8px 20px;
  }
  @media (max-width: 991px) {
    width: ${(props) => props.budget ? 'calc(100vw - 35px)' : undefined};
  }
`;
const Title = styled.div`
  font-weight: 700;
  line-height: 150%;
  margin: 0px auto;
  display: flex;
  align-items: center;
  column-gap: 10px;

  @media (min-width: 992px) and (max-width: 1300px) {
    font-size: 14px;
  }
`;

const Button = ({ title, icon, bg, budget, color, isLoading, disabled, w, onClick, iconRight }: ButtonProps) => {
  return (
    <Btn 
      w={w} 
      title={title}
      bg={bg}
      budget={budget}
      color={color}
      disabled={disabled}
      onClick={onClick}
      style={{
        backgroundColor: isLoading ? "#90998F" : "",
        cursor: isLoading ? "progress" : "pointer",
        color: isLoading ? "#B5BBB4" : "#F7F8F7"
      }}
    >
      {!isLoading && icon && !iconRight && icon}
      {isLoading 
        ? <CircularProgress 
            color='inherit' 
            size={20} 
            style={{
              marginLeft: "auto",
              marginRight: "0px"
            }}
          /> 
        : null
      }
      <Title
        style={{ 
          color: color, 
          margin: isLoading ? "0px auto 0px 0px" : "" 
        }}
      >
        {title} {!isLoading && iconRight && icon}
      </Title>
    </Btn>
  )
}

export default Button