import styled from "@emotion/styled";

export const NotificationPageContainer = styled.section`
  margin: -20px -40px;
  .notification-first-div {
    background: #f7f8f7;
    height: 111px;
    width: 100vw;
    padding: 6px 32px 16px 32px;
    padding-left: 40px;
    h3 {
      font-size: 35px;
      line-height: 52px;
      font-weight: 600;
      font-family: jost;
      color: #222522;
    }
    h6 {
      font-family: jost;
      font-weight: 400;
      font-size: 17px;
      line-height: 25.5px;
      color: #6a7368;
    }
  }
  .loader {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
  }
`;
export const Empty = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
`;
export const EmptyContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 30px;
  margin-top: 50px;

  @media (max-width: 576px) {
    > img {
      width: 100%;
    }
    > div:nth-of-type(1) {
      text-align: center;
    }
  }
`;
export const Text = styled.div`
  color: var(--GreyscaleTextIconBody);
  line-height: 150%;
  font-size: 17px;
  letter-spacing: 0.5px;
`;
export const Hr = styled.hr`
  border: 1px solid var(--GreyscaleBorderDefault);
  margin: 0px -40px;
  margin-bottom: 0;
  @media (min-width: 992px) and (max-width: 1300px) {
    margin: 15px -20px;
    margin-bottom: 0;
  }
  @media (max-width: 991px) {
    margin: 15px -15px;
    margin-bottom: 0;
  }
`;
export const NotificationSection = styled.div`
  .notification-feeds-box {
    div {
      img {
        @media (max-width: 430px) {
          width: 25px;
        }
      }
    }
    display: flex;
    justify-content: space-between;
    gap: 15px;
    background: white;
    height: auto;
    padding: 12px 32px 12px 32px;
    width: 98%;
    margin-bottom: 10px;
    .notification-feed-second-box {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 10px;
      .notification-name-and-date-section {
        display: flex;
        justify-content: space-between;
        align-items: center;
        @media (max-width: 430px) {
          flex-direction: column;
          align-items: flex-start;
        }
        h3 {
          font-family: jost;
          font-weight: 600;
          font-size: 17px;
          line-height: 25px;
          color: #222522;
          @media (max-width: 430px) {
            font-size: clamp(5px, 5.5vw, 14px);
            line-height: 20px;
            width:100%;
          }
        }
        h6 {
          font-family: jost;
          font-weight: 500;
          font-size: 14px;
          line-height: 21px;
          color: #6a7368;
          @media (max-width: 430px) {
            font-size: clamp(2px, 3vw, 12px);
            line-height: 20px;
          }
        }
      }
      .notification-description-box {
        display: flex;
        gap: 10px;
        flex-direction: column;
        h6 {
          font-family: jost;
          font-weight: 400;
          font-size: 14px;
          line-height: 21px;
          color: #6a7368;
          @media (max-width: 430px) {
            font-size: 12px;
            line-height: 18px;
          }
        }
        .from-and-goto-box {
          display: flex;
          align-items: center;
          justify-content: space-between;
          div {
            display: flex;
            gap: 5px;
            h5 {
              font-family: jost;
              font-weight: 500;
              font-size: 14px;
              line-height: 21px;
              color: #222522;
              @media (max-width: 430px) {
                font-size: 12px;
                line-height: 20px;
              }
            }
          }
          h6 {
            padding: 4px 14px 4px 16px;
            border-radius: 100px;
            background: #eff0ef;
            font-family: jost;
            font-weight: 500;
            font-size: 14px;
            line-height: 21px;
            color: #6a7368;
            @media (max-width: 430px) {
              font-size: 10px;
              line-height: 20px;
              width: 55%;
              text-align: center;
            }
          }
        }
      }
    }
  }
`;
