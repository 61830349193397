import { ApiResponse, Page, PagedApiResponse } from "../../types/apiResponse";
import { IApi, IQueryParams } from "../../types/apiTypes";
import { INotification } from "../../types/notification";

export const getUserNotifications = (
  api: IApi,
  query: IQueryParams
): Promise<Page<INotification>> => {
  const url = "notification";
  return api
    .getPage(url, query)
    .then((result: PagedApiResponse<INotification>) => {
      return result.data;
    })
    .catch((error) => {
      return new Promise((resolve, reject) => {
        reject(error);
      });
    });
};

export const deleteUserNotification = (api: IApi, id: string): Promise<any> => {
  const url = `notification/${id}`;
  return api
    .deleteData(url)
    .then((result) => {
      return JSON.parse(result.data);
    })
    .catch((error) => {
      return new Promise((resolve, reject) => {
        reject(error);
      });
    });
};

export const getUserNotificationCount = (api: IApi): Promise<number> => {
  const url = "notification/count";
  return api
    .get(url)
    .then((result: ApiResponse<number>) => {
      return result.data;
    })
    .catch((error) => {
      return new Promise((resolve, reject) => {
        reject(error);
      });
    });
};

export const getUserNotification = (
  api: IApi,
  id: string
): Promise<INotification> => {
  const url = `notification/${id}`;
  return api
    .get(url)
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      return new Promise((resolve, reject) => {
        reject(error);
      });
    });
};
