import styled from "@emotion/styled";

export const Wrapper = styled.section`
  .flex_1 {
    background-color: var(--Grey200);
    margin-left: -40px;
    margin-right: -40px;
    padding: 20px 40px;

    @media (min-width: 992px) and (max-width: 1300px) {
      margin-left: -20px;
      margin-right: -20px;
      padding: 20px;
    }
  }

  .img_container {
    margin: 20px auto 0px 20px; 
    column-gap: 10px;

    @media (max-width: 991px) {
      margin: 0px
    }
  }

  .btn_container_1 {
    width: calc(100vw - 450px);
    margin-top: 40px;

    @media (min-width: 992px) and (max-width: 1300px) {
      width: calc(100vw - 310px);
    }

    @media (max-width: 991px) {
      width: 100%;
    }
  }

  .check_text {
    @media (max-width: 991px) {
      margin-left: -10px;
      column-gap: 5px;
      margin-bottom: 20px
    }
  }
`;
export const Flex = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 991px) {
    flex-direction: column;
    align-items: flex-start;
    row-gap: 10px;
  }
`;
export const VStack = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media (max-width: 991px) {
    row-gap: 5px;
  }
`;
export const Col = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 20px;

  @media (max-width: 991px) {
    row-gap: 10px;
  }
`;
export const Header = styled.div`
  color: var(--GreyscaleTextIconBody);
  font-size: 24px;
  font-weight: 700;
  line-height: 150%;
  margin-bottom: 10px;
`;
export const Text = styled.div`
  color: var(--GreyscaleTextIconSubtitle);
  line-height: 150%;
  font-size: 17px;
`;
export const SubHeader = styled.div`
  font-size: 20px;
  font-weight: 600;
  line-height: 150%; 
  color: var(--GreyscaleTextIconBody);
  margin-bottom: 20px;
`;