import {
  AppendBlobAppendBlockOptions,
  BlobServiceClient,
  BlockBlobParallelUploadOptions,
} from "@azure/storage-blob";
import { ApiResponse } from "../../types/apiResponse";
import { IApi } from "../../types/apiTypes";

export interface MediaUploadTokens {
  accountUrl: string;
  projectFileContainerName: string;
  projectFileContainerToken: string;
  profileImageContainerName: string;
  profileImageContainerToken: string;
}

export const getUploadTokens = (api: IApi): Promise<MediaUploadTokens> => {
  return api
    .get("media/tokens/upload")
    .then((data: ApiResponse<MediaUploadTokens>) => {
      return data.data;
    });
};

export const uploadToStorage = async (
  url: string,
  container: string,
  blobName: string,
  content: any
): Promise<{ uri: string, fileName: string }> => {
  const blobServiceClient = new BlobServiceClient(url);
  const containerClient = blobServiceClient.getContainerClient(container);
  const fileName = `${Date.now().toString(16)}-${blobName}`;
  const blockBlobClient = containerClient.getBlockBlobClient(fileName);
  const options: BlockBlobParallelUploadOptions = {
    blobHTTPHeaders: { blobContentType: content.type },
    maxSingleShotSize: 100000000, //100MB threshold
  };
  await blockBlobClient.uploadData(content, options);
  return { uri: blockBlobClient.url, fileName: fileName };
};

export const uploadChunksToStorage = async (
  url: string,
  container: string,
  blobName: string,
  content: any,
  onProgress: (uploadedBytes: number) => void
): Promise<{ uri: string; bytes: number, fileName: string }> => {
  const blobServiceClient = new BlobServiceClient(url);
  const containerClient = blobServiceClient.getContainerClient(container);
  const fileName = `${Date.now().toString(16)}-${blobName}`;
  const appendBlobClient = containerClient.getAppendBlobClient(fileName);

  await appendBlobClient.create({
    blobHTTPHeaders: { blobContentType: content.type },
  });
  let bytesUploaded = 0;
  const appendBlobOptions: AppendBlobAppendBlockOptions = {
    onProgress: (progress) => {
      bytesUploaded = progress.loadedBytes;
      onProgress(progress.loadedBytes);
    },
  };
  await appendBlobClient.appendBlock(content, content.size, appendBlobOptions);
  return { uri: appendBlobClient.url, bytes: bytesUploaded, fileName: fileName };
};
