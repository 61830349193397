import styled from "@emotion/styled";

export const MajorBar = styled.section`
  width: 99%;
  margin: auto;
  height: 76px;
  border-radius: 8px;
  padding: 8px 16px 8px 16px;
  position: relative;

  display: flex;
  align-items: center;
  gap: 10px;
  .majorBar-second-block {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 10px;
    flex-wrap: wrap;
    #h5 {
      display: flex;
      font-size: 14px;
      font-weight: 500;
      font-family: jost;
      color: #000000;
      white-space: nowrap;
      overflow: hidden;
      width: 150px;
      text-overflow: ellipsis;
    }
    .loader-detail {
      display: flex;
      gap: 10px;
      #h6 {
        font-family: jost;
        font-size: 12px;
        font-weight: 500;
        color: #6a7368;
        word-wrap: break-word;
      }
    }
  }
`;

export const FileName = styled.span`
  font-weight: 500;
  color: #222522;
  line-height: 21px;
  font-size: 14px;
  text-transform: uppercase;
`;
export const FileExtension = styled.span`
  color: #6a7368;
  font-size: 14px;
  font-weight: 500;
  text-transform: lowercase;
`;
