import CloseButton from "../../../assets/icons/close-button.svg";
import GreenButton from "../../../assets/icons/green-button-with-paperPlane.svg";
import { Typography } from "@mui/material";
import { PreviewStyle } from "./style";
import { useRef } from "react";

interface IMediaPreviewProps {
  file: any;
  showTextBox?: boolean;
  text?: string;
  close: () => void;
  onSubmit: () => void;
  onTextChange?: (text: string) => void;
}

const MediaPreview = ({
  file,
  close,
  onSubmit,
  showTextBox = true,
  onTextChange,
  text,
}: IMediaPreviewProps) => {
  const textBoxRef = useRef<HTMLInputElement>(null);
  const uri = URL.createObjectURL(file);

  return (
    <div>
      <PreviewStyle>
        <div className="preview-first-block">
          <img src={CloseButton} onClick={close} />
          <Typography variant="h4">{}Preview</Typography>
        </div>
        <div className="show-preview">
          <embed src={uri} type={file.type} width="100%" height="100%" />
        </div>
        <div className="send-preview">
          <div style={{ background: showTextBox ? "#ffffff" : "none" }}>
            {showTextBox ? (
              <input
                value={text}
                ref={textBoxRef}
                type="text"
                placeholder="Write a message"
                onChange={(e) => onTextChange?.(e.target.value)}
                onKeyDown={(e) =>
                  e.key === "Enter" && !e.shiftKey && onSubmit()
                }
              />
            ) : null}
            <img src={GreenButton} onClick={onSubmit} />
          </div>
        </div>
      </PreviewStyle>
    </div>
  );
};
export default MediaPreview;
