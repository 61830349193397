// active icons
import activeProject from "../assets/icons/active-project.svg";
import activeCall from "../assets/icons/active-call.svg";
import activeFile from "../assets/icons/active-files.svg";
import activeChat from "../assets/icons/active-chat.svg";
import activeSettings from "../assets/icons/active-settings.svg";
import activeNotification from "../assets/icons/active-notification.svg";

// inactive icons
import inactiveProject from "../assets/icons/inactive-project.svg";
import inactiveCalls from "../assets/icons/inactive-call.svg";
import inactiveFiles from "../assets/icons/inactive-files.svg";
import inactiveChat from "../assets/icons/inactive-chat.svg";
import inactiveSettings from "../assets/icons/inactive-settings.svg";
import inactiveNotifications from "../assets/icons/inactive-notification.svg";

export const routes = [
  {
    name: "Projects",
    activeIcon: activeProject,
    inactiveIcon: inactiveProject,
    path: "/projects",
    subTitle: "View and manage your projects.",
  },
  {
    name: "Calls",
    activeIcon: activeCall,
    inactiveIcon: inactiveCalls,
    path: "/calls",
    subTitle: "View and manage your calls.",
  },
  {
    name: "Files",
    activeIcon: activeFile,
    inactiveIcon: inactiveFiles,
    path: "/files",

    subTitle: "View and manage your files",
  },
  {
    name: "Chat",
    activeIcon: activeChat,
    inactiveIcon: inactiveChat,
    path: "/chat",
    subTitle: "View and manage your chats",
  },
  {
    name: "Settings",
    activeIcon: activeSettings,
    inactiveIcon: inactiveSettings,
    path: "/settings",
    subTitle: "View and manage your settings",
  },
  {
    name: "Notifications",
    activeIcon: activeNotification,
    inactiveIcon: inactiveNotifications,
    path: "/notifications",
    subTitle: "View and manage your notifications",
  },
];
