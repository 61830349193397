import "./App.css";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import AppRoutes from "./AppRoutes";
import "./App.css";
import MsalAuthenticationWrapper from "./components/Authentication/MsalAuthenticationWrapper";
import AppContextProvider from "./context/Providers/AppContext";
import { CssBaseline } from "@mui/material";
import HubContextProvider from "./context/Providers/HubContext";
import MainLayout from "./layouts/main";

interface IAppProps {
  msalInstance: PublicClientApplication;
}

const App = ({ msalInstance }: IAppProps) => {
  return (
    <>
      <CssBaseline />
      <MsalProvider instance={msalInstance}>
        <MsalAuthenticationWrapper>
          <AppContextProvider>
            <HubContextProvider>
              <MainLayout>
                <AppRoutes />
              </MainLayout>
            </HubContextProvider>
          </AppContextProvider>
        </MsalAuthenticationWrapper>
      </MsalProvider>
    </>
  );
};

export default App;