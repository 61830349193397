import styled from "@emotion/styled";

export const Container = styled.main`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    row-gap: 30px;
    overflow-y: auto;
    margin-right: -10px;

    .date-time-container {
        .p-text {
            font-size: 14px;
            line-height: 21px;
            color: var(--GreyscaleTextIconSubtitle);

            @media (max-width: 991px) {
                width: calc(100vw - 35px);
            }
        }
        article {
            display: flex;
            // justify-content: space-between;
            column-gap: 20px;
            align-items: flex-start;
            margin-top: 15px;
            width: calc(100vw - 450px);

            @media (min-width: 992px) and (max-width: 1300px) {
                width: calc(100vw - 310px);
            }

            @media (max-width: 991px) {
                width: calc(100vw - 35px);
                flex-direction: column;
                row-gap: 25px;
            }
        }
    }

    .react-calendar {
        border: 1px solid var(--Grey200);
    }
    .react-calendar__tile--active {
        background: var(--PrimarySurfaceDefault) !important;
        color: var(--WhiteSurface);
        font-weight: 500;
    }
    .react-calendar__tile--active:hover {
        background: var(--PrimarySurfaceDefault) !important;
        opacity: 0.8 !important;
    }
`;
export const Header = styled.section`
    margin-bottom: -10px;

    div {
        display: flex;
        align-items: center;
        column-gap: 10px;
    }
    h4 {
        font-weight: 700;
        font-size: 24px;
        line-height: 36px;
        color: var(--GreyscaleTextIconBody);
    }
    p {
        font-size: 14px;
        line-height: 21px;
        color: var(--GreyscaleTextIconSubtitle);
        margin-top: -30px;
    }
`;
export const Section = styled.section`
    display: flex;
    flex-direction: column;
    row-gap: 5px;
`;
export const TimePicker = styled.div`
    border: 1px solid var(--Grey200);
    max-width: 100%;
    width: 350px;
    height: 282px;
    overflow-y: auto;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
    font-size: 14px;
    line-height: 21px;
    color: var(--GreyscaleTextIconSubtitle);
    cursor: pointer;
    padding: 10px;

    div {
        border: 1px solid var(--Grey200);
        border-radius: 100px;
        text-align: center;
        max-height: 23px;
    }
    div:hover {
        transform: scale(1.05)
    }
`;
export const Textarea = styled.textarea`
    color: var(--GreyscaleTextIconBody);
    font-size: 14px;
    line-height: 150%;
    border: 1.5px solid var(--Grey200);
    background: none;
    border-radius: 4px;
    padding: 10px 16px !important;
    font-weight: 500;
    width: calc(100vw - 450px);

    ::placeholder {
        color: var(--GreyscaleSurfaceDisabled);
        font-weight: 400;
    }

    @media (min-width: 992px) and (max-width: 1300px) {
        width: calc(100vw - 310px);
    }

    @media (max-width: 991px) {
        width: calc(100vw - 35px);
    }
`;
export const Spacer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: calc(100vw - 450px);

  @media (min-width: 992px) and (max-width: 1300px) {
    width: calc(100vw - 310px);
  }
  @media (max-width: 991px) {
    flex-direction: column;
    row-gap: 10px;
    width: calc(100vw - 35px);
  }
`;