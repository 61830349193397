import styled from "@emotion/styled";

export const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: auto;
  height: 100%;

  #isSort {
    display: none;
  }
  #isSort.active {
    display: block;
  }
  .column-sub-container {
    display: flex;
    align-items: center;
  }
`;

export const TableDesign = styled.table`
  width: calc(100vw - 350px);
  margin-left: -42px;
  overflow: scroll;
  heightL 100%;
`;

export const TableNavigationBar = styled.div`
  margin-left: 0px;
  background: #eff0ef;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: calc(100vw - 350px);
  height: 61px;
  gap: 10px;
  border-bottom: solid #b5bbb4 1px;
  @media (min-width: 992px) and (max-width: 1300px) {
    width: calc(100vw - 250px);
  }
  @media (max-width: 991px) {
    width: 107vw;
  }
  @media (max-width: 600px) {
    flex-direction: column;
    gap: 13px;
    height: auto;
    padding-bottom: 10px;
    padding-top: 10px;
  }
  .first-block {
    display: flex;
    height: auto;
    gap: 10px;
    margin-left: 41px;
    align-items: center;
    width: 80%;
    overflow: auto;
    button {
      border: none;
      background: none;
      font-family: jost;
      font-size: 14px;
      padding: 0;
      height: 37px;
      width: 81px;
      font-weight: 400;
      border-radius: 5px;
      cursor: pointer;
      color: #6a7368;
      border: none;
      background: none;
    }
    button.active {
      color: #117000;
      border: solid 1px #33ff0f;
      background: #dcffd6;
    }
    @media (max-width: 600px) {
      width: 100%;
      justify-content: center;
      margin-left: 0px;
    }
  }

  .second-block {
    width: 92%;
    padding-right: 5%;
    @media (min-width: 992px) {
      padding-right: 0px;
    }
    @media (min-width: 1301px) {
      padding-right: 2%;
    }
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 5px;
    @media (max-width: 600px) {
      gap: 5px;
      justify-content: center;
      padding-right: 0px;
      width: 85%;
      margin: auto;
      padding-left: 10px;
    }
    .sub-second-block {
      border: solid 1px #d2d6d2;
      padding: 10px 20px 10px 20px;
      width: 70%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 38px;
      border-radius: 100px;
      background: #ffffff;
      input {
        width: 100%;
        background: none;
        border: none;
        outline: none;
        font-family: jost;
        font-weight: 400;
        font-size: 17px;
        line-height: 25.5px;
        color: #808080;
      }
      @media (max-width: 600px) {
        width: 100%;
      }
    }
    .sorting {
      position: relative;
      button {
        height: 37px;
        border: none;
        background: #ffffff;
        border-bottom: solid 1px #b5bbb4;
        display: flex;
        gap: 2px;
        justify-content: space-evenly;
        align-items: center;
        padding: 2px;
        font-family: jost;
        font-weight: 400;
        font-size: clamp(1px, 1vw, 14px);
        width: clamp(20px, 12vw, 150px);
        line-height: 21px;
        color: #6a7368;
        cursor: pointer;
        margin-right: 10px;
        p {
          width: 60%;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        @media (max-width: 900px) {
          padding: 10px;
          justify-content: center;
          font-size: clamp(5px, 1.5vw, 14px);
          height: clamp(20px, 5vw, 30px);
          width: clamp(40px, 15vw, 150px);
          img {
            display: none;
          }
        }
        @media (max-width: 600px) {
          width: 100px;
          height: 37px;
          font-size: 10px;
          img {
            display: block;
          }
        }
      }
    }
    .sorting-list {
      width: 200px;
      box-shadow: 0px 4px 10px 10px #d8d8d8;
      position: absolute;
      right: 10px;
      margin-top: 5px;
      background: #f7f8f7;
      z-index: 3;
      li {
        width: 100%;
        height: 45px;
        border: none;
        border-bottom: solid 1px #d8d8d8;
        padding: 12px 16px 12px 16px;
        list-style-type: none;
        font-size: 14px;
        line-height: 21px;
        font-weight: 400;
        font-family: jost;
        color: #222522;
        cursor: pointer;
        :hover {
          background: #e2e4e2;
        }
      }
    }
  }
`;

export const Search = styled.input``;

export const TableWrapper = styled.div`
  margin-left: 0px;
  width: calc(100vw - 350px);
  @media (min-width: 992px) and (max-width: 1300px) {
    width: calc(100vw - 250px);
  }
  @media (max-width: 991px) {
    width: calc(100vw + 20px);
  }

  .skeleton-table-row {
    border-bottom: solid 1px #e0e0e0;

    .skeleton-table-row-light {
      height: 0px;
      width: 20px;
      position: relative;
      top: 17px;
      animation: light 3s infinite;
      box-shadow: 0px 0px 20px 20px rgb(220, 255, 214);
    }
  }
  @keyframes light {
    from {
      left: 0%;
    }
    to {
      left: 100vw;
    }
  }
`;

export const Text = styled.text`
  line-height: 21px;
  font-size: 14px;
  font-weight: 550;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--GreyscaleTextIconSubtitle);
`;

export const PaginationStyle = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  height: auto;
  gap: 10px;
  justify-content: center;
  @media (max-width: 480px) {
    flex-direction: column-reverse;
  }
  .pagination {
    @media (max-width: 480px) {
      margin: auto;
    }
  }
  margin-top: 5%;
  .page-size {
    width: 100px;
    background: #f7f8f7;
    padding-left: 0px;
    padding: 0px;
    text-align: left;
    @media (max-width: 480px) {
      margin: auto;
      margin-bottom: 5%;
    }
    div {
      display: flex;
      align-items: center;
    }
    p {
      width: 100%;
      height: 0;
      border: none;
      padding: 10px;
      list-style-type: none;
      font-size: 14px;
      font-weight: 400;
      font-family: jost;
      color: #222522;
      cursor: pointer;
      box-shadow: 0px 1px 10px 5px #d8d8d8;
      background: #f7f8f7;
      padding-bottom: 25%;
      padding-top: 6%;
      display: flex;
      align-items: baseline;
      justify-content: space-between;
    }
    li {
      width: 100%;
      height: 0;
      padding: 10px;
      list-style-type: none;
      font-size: 14px;
      font-weight: 400;
      font-family: jost;
      color: #222522;
      cursor: pointer;
      background: #e2e4e2;
      padding-bottom: 25%;
      padding-top: 6%;
      :hover {
        background: #f7f8f7;
      }
    }
  }
`;

export const EmptyContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;