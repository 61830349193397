import React from 'react'
import styled from '@emotion/styled'
import { CircularProgress } from '@mui/material';

type ButtonProps = {
    type: "button" | "submit" | "reset" | undefined;
    style?: React.CSSProperties
    title: string
    isLoading?: boolean
    disabled?: boolean
    icon?: any
    secondary?: boolean
    onClick?: ()=> void
}

const CustomButton = ({ type, title, style, isLoading, disabled, icon, onClick, secondary }: ButtonProps) => {
  const Button = styled.button`
    background-color: ${isLoading || disabled ? "#90998F" : secondary ? "#111311" : "#117000"};
    display: ${isLoading && "flex"};
    align-items: center;
    justify-content: center;
    height: 50px;
    color: ${isLoading ? "#B5BBB4" : "#F7F8F7"};
    font-size: 17px;
    font-weight: 700;
    line-height: 150%;
    border-radius: 4px;
    outline: none;
    border: none;
    cursor: ${isLoading ? "progress" : "pointer"};

    &:hover {
      background-color: ${ disabled ? "" : secondary ? "#117000" : "#111311"}
    }
    @media (max-width: 768px) {
      width: 100%;
    }
  `;

  const ButtonProp = styled.div`
    padding-left: ${isLoading ? "10px" : 0};
    display: ${icon ? "flex" : "block"};
    justify-content: center;
    align-items: center;
    column-gap: 10px
  `;

  return (
    <Button type={type} style={style} disabled={disabled} onClick={onClick}>
      {isLoading ? <CircularProgress color='inherit' size={20} /> : null}
      <ButtonProp>
        {icon && icon}
        {title}
      </ButtonProp>
    </Button>
  )
}

export default CustomButton