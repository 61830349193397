import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import Button from "../../components/Button";
import { ArrowLeft } from "phosphor-react";
import { useNavigate } from "react-router-dom";
import CustomInput from "../../components/Input";
import useNotification from "../../hooks/useNotification";

const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (min-width: 992px) and (max-width: 1300px) {
    margin-right: 10px;
  }
`;
const SubFlex = styled.div`
  display: flex;
  column-gap: 10px;
  align-items: center;
  width: calc((100vw - 450px) / 2.1);

  @media (min-width: 992px) and (max-width: 1300px) {
    width: calc((100vw - 250px) / 2.1);
  }
  @media (max-width: 991px) {
    width: 100vw;
  }
`;
const VStack = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;
const Header = styled.div`
  color: var(--GreyscaleTextIconBody);
  font-size: 30px;
  font-weight: 700;
  line-height: 150%;
  letter-spacing: -1.8px;
  display: flex;
  align-items: center;
  column-gap: 15px;

  @media (min-width: 992px) and (max-width: 1300px) {
    font-size: 28px;
  }
`;
const Text = styled.div`
  color: var(--GreyscaleTextIconBody);
  line-height: 150%;
  font-size: 14px;
  font-weight: 500;
`;
const Hr = styled.hr`
  border: 1px solid var(--GreyscaleSurfaceDefault);
  margin: 15px -40px;

  @media (min-width: 992px) and (max-width: 1300px) {
    margin: 15px -20px;
  }
  @media (max-width: 991px) {
    margin: 15px -15px;
  }
`;
const Form = styled.section`
  > hr {
    border: 1px solid var(--GreyscaleBorderDisabled);
  }
`;

const ChangePassword = () => {
  const navigate = useNavigate();
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [retypePassword, setRetypePassword] = useState("");
  const [disableBtn, setDisableBtn] = useState(true);
  const { notify } = useNotification();

  const handleSavePassword = () => {
    notify("Password changed successfully", "success");
    navigate(-1);
  };

  useEffect(() => {
    if (currentPassword && newPassword && retypePassword) {
      setDisableBtn(false);
    } else {
      setDisableBtn(true);
    }
  }, [currentPassword, newPassword, retypePassword]);

  return (
    <>
      <Flex>
        <VStack>
          <Header>
            <ArrowLeft size={32} weight="bold" onClick={() => navigate(-1)} />
            Change password
          </Header>
          <Text>Create a password that is atleast 8 characters long</Text>
        </VStack>

        <Button
          title="Save password"
          bg="var(--PrimaryBorderLighter)"
          color="var(--GreyscaleSurfaceSubtle)"
          disabled={disableBtn}
          onClick={() => !disableBtn && handleSavePassword()}
        />
      </Flex>
      <Hr />

      <Form>
        <Flex>
          <Text>Enter current password</Text>

          <SubFlex>
            <CustomInput
              placeholder="Enter current password"
              type="password"
              value={currentPassword}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setCurrentPassword(e.target.value)
              }
              half
              settings
            />
          </SubFlex>
        </Flex>
        <Hr />

        <Flex>
          <Text>Type your new password</Text>

          <SubFlex>
            <CustomInput
              placeholder="Enter new password"
              type="password"
              value={newPassword}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setNewPassword(e.target.value)
              }
              half
              settings
            />
          </SubFlex>
        </Flex>
        <Hr />

        <Flex>
          <Text>Retype your new password</Text>

          <SubFlex>
            <CustomInput
              placeholder="Retype new password"
              type="password"
              value={retypePassword}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setRetypePassword(e.target.value)
              }
              half
              settings
            />
          </SubFlex>
        </Flex>
        <Hr />

        <Button
          title="Forgot password"
          bg="var(--Grey100)"
          color="var(--GreyscaleSurfaceDisabled)"
          w="180px"
          onClick={() => console.log("Forgot password")}
        />
      </Form>
    </>
  );
};

export default ChangePassword;
