import { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { CaretDown, CaretUp } from 'phosphor-react';
import Prompts from '../Prompts'
import Flashcard from '../Flashcard';

// assets
import algocipherBudgetIcon from "../../assets/icons/algocipher-budget.svg";
import userBudgetIcon from "../../assets/icons/user-budget.svg";
import calendarIcon from "../../assets/icons/calendar-icon.svg";

interface ProposalsProps {
    icon?: 'user' | 'algocipher',
    text?: string,
    subText?: string,
    hide?: boolean,
    index?: number,
    budget: any,
    timeline: any,
}

const Wrapper  = styled.section`
    margin: 30px auto;
`;
const Section = styled.section`
    display: flex;
    align-items: center;
    column-gap: 15px;
`;
const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const Spacer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 991px) {
        flex-direction: column;
        row-gap: 10px;
    }
`;
const VStack = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 5px;
`;
const Text = styled.div`
    font-size: 17px;
    font-weight: 500;
    line-height: 150%;
    color: var(--GreyscaleTextIconBody);
`;
const Subtext = styled.div`
    font-size: 14px;
    line-height: 150%;
    color: var(--GreyscaleBorderDarker);
`;
const SubContainer = styled.div`
    display: flex;
    align-items: center;
    column-gap: 10px;
`;

const Proposals = ({ icon, text, subText, hide, index, budget, timeline }: ProposalsProps) => {
  const [hideProposal, setHideProposal] = useState(false);

  useEffect(()=>{
    if (hide) {
        setHideProposal(true);
    } 
  },[hide])

  return (
    <Wrapper>
        {index === 2 && 
            (<Flex>
                <Section style={{ opacity: hideProposal ? 0.3 : 1}}>
                    <img src={icon === "user" ? userBudgetIcon : algocipherBudgetIcon} alt="icon" />

                    <VStack>
                        <Text>{text}</Text>
                        <Subtext>{subText}</Subtext>
                    </VStack>
                </Section>
                
                {hide && <div onClick={()=>setHideProposal(!hideProposal)}>
                    {hideProposal ? <CaretDown size={25} weight="bold" /> : <CaretUp size={25} weight="bold" /> }
                </div>}
            </Flex>)
        }

        {!hideProposal && 
            <>
                <div>
                    <Text style={{ fontSize: "14px", margin: "20px auto 10px" }}>{index === 2 ? "Negotiable budget" : "Agreed budget and timeline"}</Text>

                    {budget.cost !== 0 ? 
                        <VStack>
                            <Prompts title='Cost' color='var(--Grey500)' />
                            <Flashcard 
                                style={{
                                    background: "var(--Grey100)",
                                    border: "1px solid var(--Grey200)"
                                }}
                                content={
                                    <SubContainer>
                                        <div style={{ color: "var(--GreyscaleTextIconBody)"}}>$</div>
                                        <div>{budget.cost}</div>
                                    </SubContainer>
                                }
                            />
                        </VStack> :   
                        <Spacer>
                            <VStack>
                                <Prompts title='From' color='var(--Grey500)' />
                                <Flashcard 
                                    half
                                    style={{
                                        background: "var(--Grey100)",
                                        border: "1px solid var(--Grey200)"
                                    }}
                                    content={
                                        <SubContainer>
                                            <div style={{ color: "var(--GreyscaleTextIconBody)"}}>$</div>
                                            <div>{budget.from}</div>
                                        </SubContainer>
                                    }
                                />
                            </VStack>

                            <VStack>
                                <Prompts title='To' color='var(--Grey500)' />
                                <Flashcard 
                                    half
                                    style={{
                                        background: "var(--Grey100)",
                                        border: "1px solid var(--Grey200)"
                                    }}
                                    content={
                                        <SubContainer>
                                            <div style={{ color: "var(--GreyscaleTextIconBody)"}}>$</div>
                                            <div>{budget.to}</div>
                                        </SubContainer>
                                    }
                                />
                            </VStack>
                        </Spacer>
                    }
                </div>

                <div>
                    <Text style={{ fontSize: "14px", margin: "20px auto 10px" }}>Estimated period</Text>
                    <Spacer className='flex-budget'>
                        <VStack>
                            <Flashcard 
                               half
                                style={{
                                    background: "var(--Grey100)",
                                    border: "1px solid var(--Grey200)"
                                }}
                                content={
                                    <SubContainer>
                                        <img src={calendarIcon} alt="calendar" />
                                        <div>{timeline}</div>
                                    </SubContainer>
                                }
                            />
                        </VStack>
                    </Spacer>
                </div>
            </>
        }
    </Wrapper>
  )
}

export default Proposals