import { HubConnection } from "@microsoft/signalr";

export const HubEvents = {
  onNotification: "onNotification",
  onChatMessage: "onChatMessage"
};


export interface OnNotificationHubData {
  Id: string;
}

// export interface OnChatMessageHubData {
//   MessageId: string;
//   ProjectId: string;
//   Text: string;
//   SentBy: string;
//   MessageType: string;
//   ProjectStatus: string;
//   FileType: string;
//   FileUri: string;
//   FileThumbnailUri: string;
//   Saved: string;
  
// }

export interface IHubMessagePayload {
  event: string;
  data: string;
}