import styled from "@emotion/styled";

export const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  justify-content: right;
  padding-bottom: 20px;

  @media (min-width: 992px) and (max-width: 1300px) {
    margin-right: 10px;
  }
`;
export const FlexTable = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;
  min-width: 200px;
`;
//   display: flex;
//   align-items: center;
//   column-gap: 0.75vw;
// `;
// export const VStack = styled.div`
//   display: flex;
//   flex-direction: column;
//   align-items: flex-start;
// `;
// export const Header = styled.div`
//   color: var(--GreyscaleTextIconBody);
//   font-size: 30px;
//   font-weight: 700;
//   line-height: 150%;
//   display: flex;
//   align-items: center;

//   > img {
//     margin-right: 10px;
//   }

//   @media (min-width: 992px) and (max-width: 1300px) {
//     font-size: 28px;
//   }
// `;
export const Text = styled.div`
  color: var(--GreyscaleTextIconBody);
  line-height: 150%;
  font-size: 14px;
  font-weight: 500;
`;
//   border: 1px solid var(--GreyscaleBorderDefault);
//   margin: 15px -40px;

//   @media (min-width: 992px) and (max-width: 1300px) {
//     margin: 15px -20px;
//   }
//   @media (max-width: 991px) {
//     margin: 15px -15px;
//   }
// `;
export const Empty = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;
`;
export const EmptyContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 30px;
  margin-top: 50px;

  @media (max-width: 576px) {
    > img {
      width: 100%;
    }
    > div:nth-of-type(1) {
      text-align: center;
    }
  }
`;
//   .schedule_header {
//     font-size: 24px;
//   }
//   .schedule_text {
//     color: var(--GreyscaleSurfaceDisabled);
//   }
//   .text_area_1 {
//     width: calc(100vw - 450px);

//     @media (min-width: 992px) and (max-width: 1300px) {
//       width: calc(100vw - 310px);
//     }
//   }
//   .flex_deets_1 {
//     justify-content: flex-start;
//   }
//   #to {
//     font-size: 18px;
//     font-weight: 600;
//     margin: 35px 0.7vw 0px;

//     @media (min-width: 1301px) and (max-width: 1800px) {
//       margin: 35px 0.55vw 0px;
//     }
//     @media (min-width: 992px) and (max-width: 1300px) {
//       margin: 35px 0.9vw 0px;
//     }
//   }
// }
// `;
// export const Col = styled.section`
//   margin-top: 30px;
//   display: flex;
//   flex-direction: column;
//   row-gap: 30px;
// `;
// export const Subcol = styled.article`
//   display: flex;
//   flex-direction: column;
//   row-gap: 10px;
// `;
// export const Textarea = styled.textarea`
//   color: var(--GreyscaleTextIconBody);
//   font-size: 17px;
//   line-height: 150%;
//   border: 1.5px solid var(--Grey200);
//   background: none;
//   border-radius: 4px;
//   padding: 10px 16px !important;
//   font-weight: 500;

//   ::placeholder {
//     color: var(--GreyscaleSurfaceDisabled);
//     font-weight: 400;
//   }
// `;
// export const Spacer = styled.div`
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   width: calc(100vw - 450px);
//   margin-top: 10px;

//   @media (min-width: 992px) and (max-width: 1300px) {
//     width: calc(100vw - 310px);
//   }
// `;
