import styled from "@emotion/styled";

interface InputProps {
  type: string;
  half?: boolean;
  settings?: boolean;
  calls?: boolean;
  quarter?: boolean;
  placeholder?: string;
  value?: string;
  onChange?: any;
  icon?: any;
  style?: React.CSSProperties;
  iconPosition?: any;
  [props: string]: any;
}

const Wrapper = styled.section`
  position: relative;
`;
const Icon = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
`;
const Input = styled.input<InputProps>`
  font-size: 14px;
  color: var(--GreyscaleTextIconBody);
  font-weight: 500;
  line-height: 150%;
  border: 1.5px solid var(--Grey200);
  background: none;
  border-radius: 4px;
  padding: 10px 16px;
  width: ${(props) =>
    props.half
      ? "calc((100vw - 450px) / 2.1)"
      : props.quarter
      ? "calc((100vw - 450px) / 4.2)"
      : "calc(100vw - 450px)"};

  ::placeholder {
    color: var(--GreyscaleSurfaceDisabled);
    font-weight: 400;
  }

  @media (min-width: 992px) and (max-width: 1300px) {
    width: ${(props) =>
      props.half
        ? `calc((100vw - ${props.settings ? "250px" : "310px"}) / 2.1)`
        : props.quarter
        ? `calc((100vw - ${props.calls ? "310px" : "250px"} - 20px) / 4.2)`
        : `calc(100vw - ${props.settings ? "250px" : "310px"})`};
  }
  @media (max-width: 991px) {
    width: calc(100vw - 35px);
  }
`;

const CustomInput = ({
  type,
  half,
  settings,
  calls,
  quarter,
  placeholder,
  icon,
  style,
  iconPosition,
  value,
  onChange,
  ...props
}: InputProps) => {
  return (
    <Wrapper>
      {icon && <Icon style={iconPosition}>{icon}</Icon>}

      <Input
        type={type}
        placeholder={placeholder}
        style={style}
        value={value}
        half={half}
        settings={settings}
        calls={calls}
        quarter={quarter}
        onChange={onChange}
        {...props}
      />
    </Wrapper>
  );
};

export default CustomInput;
