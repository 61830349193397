import styled from "@emotion/styled";

export const FirstBar = styled.div`
  width: 100%;
  height: 85px;
  display: flex;
  align-items: baseline;
  background: #f7f8f7;
  position: relative;
`;
export const FirstBarFlexedContents = styled.div`
  display: flex;
  height: 53px;
  gap: 15px;
  img {
    width: 31.61px;
  }
  h2 {
    width: 66px;
    font-family: jost;
    font-size: 35px;
    font-weight: 600;
    line-height: 52px;
    color: #222522;
  }
`;
export const Hr = styled.hr`
  border: 1px solid var(--GreyscaleBorderDefault);
  margin: 15px -40px;
  margin-bottom: 0;
  @media (min-width: 992px) and (max-width: 1300px) {
    margin: 15px -20px;
    margin-bottom: 0;
  }
  @media (max-width: 991px) {
    margin: 15px -15px;
    margin-bottom: 0;
  }
`;
export const Spacer = styled.hr`
  margin: 15px -40px;
  margin-bottom: 0;
  margin-top: 0;
  height: 20px;
  border: none;
  background: #eff0ef;

  @media (min-width: 992px) and (max-width: 1300px) {
    margin: 15px -20px;
    margin-bottom: 0;
  }
  @media (max-width: 991px) {
    margin: 15px -15px;
    margin-bottom: 0;
  }
`;
export const FilePickerBoxContent = styled.section`
  width: 100%;
  height: auto;
  position: relative;
  padding: 55px 16px 12px 16px;
  margin: auto;
  text-align: center;
  border-radius: 12px;
  background: #e2e4e2;
  h5 {
    font-family: jost;
    font-weight: 420;
    font-size: 14px;
    line-height: 26px;
    margin: auto;
    padding-top: 10px;
    margin-bottom: 35px;
  }
`;
export const Search = styled.input``;

export const BoldTableTextStyle = {
  fontFamily: "jost",
  fontWeight: "550",
  fontSize: "14px",
  lineHeight: "21px",
  color: "#222522",
  whiteSpace: "nowrap",
  overflow: "hidden",
  width: "150px",
  textOverflow: "ellipsis",
  display: "flex",
  alignItems: "center",
  gap: "10px",
};

export const RegularTableTextStyle = {
  fontFamily: "jost",
  fontWeight: "450",
  fontSize: "14px",
  lineHeight: "21px",
  color: "#6A7368",
  whiteSpace: "nowrap",
  overflow: "hidden",
  width: "150px",
  textOverflow: "ellipsis",
  display: "flex",
  alignItems: "center",
  gap: "10px",
};