import {
  createContext,
  useContext,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import useApi from "../../hooks/api/useApi";
import { HubState, IContextProviderProps } from "../types";
import {
  HubConnection,
  HubConnectionBuilder,
  HubConnectionState,
  ILogger,
  LogLevel,
} from "@microsoft/signalr";
import { IHubMessagePayload } from "../../types/hub";
import { AppContext, HubContext } from "..";

const HubReducer: React.Reducer<HubState, Partial<HubState>> = (
  state,
  action
) => {
  return { ...state, ...action };
};

type HubEvent = Record<string, Set<(...args: any[]) => void>>;

const HubContextProvider = ({ children }: IContextProviderProps) => {
  const [connection, setConnection] = useState<HubConnection | undefined>();
  const [hubEvents, setHubEvents] = useState<HubEvent>({});

  const appState = useContext(AppContext);
  const appStateRef = useRef(appState);

  const on = (event: string, callback: (...args: any[]) => void) => {
    if (!hubEvents[event]) {
      hubEvents[event] = new Set();
    }
    hubEvents[event].add(callback);
    setHubEvents(hubEvents);
  };

  const emit = (event: string, ...args: any[]) => {
    if (!hubEvents[event]) {
      return;
    }
    hubEvents[event].forEach((cb: any) => cb(...args, appStateRef.current));
  };

  const off = (event: string, callback: (...args: any[]) => void) => {
    if (!hubEvents[event]) {
      return;
    }
    hubEvents[event].delete(callback);
    setHubEvents(hubEvents);
  };

  const initialState: HubState = {
    hub: connection,
    on: on,
    off: off,
  };

  const [state, dispatch] = useReducer(HubReducer, initialState);

  useEffect(() => {
    appStateRef.current = appState;
  }, [appState]);

  useEffect(() => {
    if (appState.api?.token && !connected()) {
      const conn = new HubConnectionBuilder()
        .withUrl(process.env.REACT_APP_HUB ?? "", {
          logger: logger,
          accessTokenFactory: accessTokenFactory,
        })
        .withAutomaticReconnect() // todo: configure better reconnection policy
        .build();

      if (conn) {
        conn.on("ReceiveMessage", handleReceiveMessage);
        conn.start().then(() => {
          setConnection(conn);
        });
      }
    }
  }, [appState.api]);

  const handleReceiveMessage = (payload: IHubMessagePayload) => {
    emit(payload.event, JSON.parse(payload.data));
  };

  const accessTokenFactory = (): string => {
    return appState.api?.token ?? "";
  };

  const logger: ILogger = {
    log: (logLevel: LogLevel, message: string) => {
      console.log(`[${logLevel.toString()}]:${message}`);
    },
  };

  const connected = (): boolean => {
    return (
      connection !== undefined &&
      [HubConnectionState.Connected, HubConnectionState.Reconnecting].includes(
        connection.state
      )
    );
  };

  return <HubContext.Provider value={state}>{children}</HubContext.Provider>;
};

export default HubContextProvider;
