import { useState, useEffect } from "react";

interface DebounceProps {
  delayMS: number;
}

const useDebounce = ({ delayMS }: DebounceProps) => {
  const [debouncedValue, setDebouncedValue] = useState();
  const [debounce, setDebounce] = useState<NodeJS.Timeout>();

  const resetValue = (val: any) => {
    if (debounce) {
      clearTimeout(debounce);
    }
    setDebounce(
      setTimeout(() => {
        setDebouncedValue(val);
      }, delayMS)
    );
  };
  return {
    setValue: resetValue,
    value: debouncedValue,
  };
};

export default useDebounce;