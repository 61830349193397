import LoadingImg from "../../../assets/icons/loading-img.svg";
import { Typography } from "@mui/material";
import { useMemo } from "react";
import { FileExtension, FileName, MajorBar } from "./style";
import { bytesToMegaBytes } from "../../../services/helpers/helpers";

interface IFileLoadingBarProps {
  fileName: string;
  fileSize: number;
  progress: number;
  onComplete: ()=> void;
}

const FileLoadingBar = ({
  fileName,
  fileSize,
  progress,
  onComplete,
}: IFileLoadingBarProps) => {
  const progressPercent = useMemo(() => {
    const percent = (progress / fileSize) * 100.0;
    return percent < 0 ? 0 : percent > 100 ? (onComplete(),100) : percent;
  }, [progress]);

  const getFileName = useMemo(() => {
    if (!fileName.includes(".")) {
      return <FileName>{fileName}</FileName>;
    }
    const extension = fileName.slice(fileName.lastIndexOf("."));
    const name = fileName.slice(0, fileName.lastIndexOf("."));
    return (
      <>
        <FileName>{name}</FileName>
        <FileExtension>{extension}</FileExtension>
      </>
    );
  }, [fileName]);

  return (
    <MajorBar
      style={{
        background: `linear-gradient(to left, #F7F8F7 ${
          100 - progressPercent
        }%, #D6E6FF 0%)`,
        transition: "1s ease-in-out",
      }}
    >
      <img src={LoadingImg} />
      <div className="majorBar-second-block">
        <Typography id="h5">{getFileName}</Typography>
        <div className="loader-detail">
          <Typography id="h6">{Math.round(progressPercent)}%</Typography>
          <Typography id="h6">
            {Math.round(bytesToMegaBytes(progress) * 100) / 100}/
            {Math.round(bytesToMegaBytes(fileSize) * 100) / 100}MB
          </Typography>
        </div>
      </div>
    </MajorBar>
  );
};
export default FileLoadingBar;
