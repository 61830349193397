import styled from '@emotion/styled';

interface PromptsProps {
  title: string,
  color?: string,
  bold?: boolean
}

const Text = styled.div<PromptsProps>`
    color: var(--GreyscaleTextIconBody);
    font-size: ${(props) => props.bold ? '17px' : '14px'};
    font-weight: ${(props) => props.bold ? 500 : 400};
    line-height: 150%;
`;
const Span = styled.span`
    font-weight: 700;
`;

const Prompts = ({ title, color, bold }: PromptsProps) => {
  return (
    <Text bold={bold} title={title} style={{ color: color }}>{title}<Span>*</Span></Text>
  )
}

export default Prompts