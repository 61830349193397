import styled from "@emotion/styled";

export const StepsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;

  @media (max-width: 498px) {
    img {
      width: 20px;
    }

    column-gap: 5px;
    justify-content: flex-start;
    margin-left: -2%;
  }

  @media (max-width: 359px) {
    img {
      width: 15px;
    }
    
    margin-left: -2.5%;
  }
`;
export const Steps = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 561px) {
    .substep4 {
      margin-bottom: 20px;
      margin-left: 2%;
    }
  }
`;
export const Substeps = styled.article`
  font-size: 14px;
  font-weight: 600;
  line-height: 150%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 5px;
  text-align: center;

  @media (max-width: 359px) {
    font-size: 12px;
  }
`;
export const Circle = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 20px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 1300px) {
    width: 28px;
    height: 28px;
    border-radius: 14px;
  }

  @media (max-width: 359px) {
    width: 25px;
    height: 25px;
    border-radius: 100%;
  }
`;