import styled from "@emotion/styled";

export const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 991px) {
    flex-direction: column;
    margin-right: 0px !important;
    align-items: flex-start;
  }
`;
export const SubFlex = styled.div`
  display: flex;
  align-items: center;
  column-gap: 15px;

  @media (max-width: 991px) {
    flex-direction: column;
  }
`;
export const Header = styled.div`
  color: var(--GreyscaleTextIconBody);
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  display: flex;
  align-items: center;
  column-gap: 10px;
  margin-top: 30px;

  > img {
    margin-bottom: 3px;
  }
  
  @media (min-width: 992px) and (max-width: 1300px) {
    font-size: 18px;
  }
`;
export const Text = styled.div`
  color: var(--GreyscaleSurfaceDisabled);
  line-height: 150%;
  font-size: 14px;
  font-weight: 500
`;
export const Section = styled.section`
  .text_area_1 {
    width: calc(100vw - 450px);

    @media (min-width: 992px) and (max-width: 1300px) {
      width: calc(100vw - 310px);
    }
  }

  #call_flex {
    column-gap: 20px !important;
  }

  #to {
    font-size: 20px;
    color: var(--GreyscaleTextIconBody);
    font-weight: 600;
    margin-top: 30px;

    @media (min-width: 992px) and (max-width: 1300px) {
      font-size: 16px;
    }
    @media (max-width: 991px) {
      margin: 20px 0px !important;
    }
  }
}
`;
export const Col = styled.section`
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  row-gap: 30px;
`;
export const Subcol = styled.article`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
`;