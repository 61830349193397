import { PublicClientApplication } from "@azure/msal-browser";
import { AppState, HubState } from "./types";
import { msalConfig } from "../authConfig";
import { createContext } from "react";
import { IUser } from "../types/user";

export const AppContext = createContext<AppState>({
  msalInstance: new PublicClientApplication(msalConfig),
  setUser: (user: IUser) => {},
  isSidebarOpen: false,
  setSidebarOpen: (isOpen: boolean) => {},
  notificationCount: 0,
  setNotificationCount: (count: number) => {},
});

export const HubContext = createContext<HubState>({
  on: (event: string, callback: (...args: any[]) => void) => {},
  off: (event: string, callback: (...args: any[]) => void) => {},
});
