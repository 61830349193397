import { Check } from "phosphor-react";
import "./index.css";

export const ToastSuccessIcon = () => {
  return <Check size={30} weight="fill" style={{
    backgroundColor: "#072E00",
    color: "#BAFFAD",
    borderRadius: '4px',
  }}/>;
};

export const ToastErrorIcon = () => {
  return <Check size={30} weight="fill" style={{
    backgroundColor: "#072E00",
    color: "#FFCCCC",
    borderRadius: '4px',
  }}/>;
}
