import styled from '@emotion/styled';

interface FlashcardProps {
  content?: any,
  style?: React.CSSProperties,
  bg?: string,
  half?: boolean,
  quarter?: boolean,
  icon?: React.ReactNode,
  border?: string
}

const Card = styled.div<FlashcardProps>`
  padding: 10px 16px;
  color: var(--GreyscaleBorderDarker);
  font-size: 14px;
  line-heigt: 150%;
  border-radius: 4px;
  width: ${(props) => (props.half ? "calc((100vw - 450px) / 2.1)" 
    : props.quarter ? "calc(((100vw - 450px) / 4) - 24px)" : "calc(100vw - 450px)"
  )};

  @media (min-width: 992px) and (max-width: 1300px) {
    width: ${(props) => (props.half ? "calc((100vw - 310px) / 2.1)"
      : props.quarter ? "calc(((100vw - 310px) / 4) - 22.5px)" : "calc(100vw - 310px)")
    };
  }
  @media (max-width: 991px) {
    width: calc(100vw - 35px)
  }
`;

const Flashcard = ({ content, style, half, icon, quarter }: FlashcardProps) => {
  return (
    <Card
      style={style}
      half={half}
      quarter={quarter}
    >
      {icon && icon}
      {content}
    </Card>
  )
}

export default Flashcard