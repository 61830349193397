import { ApiResponse, Page, PagedApiResponse } from "../../types/apiResponse";
import { IApi, IQueryParams } from "../../types/apiTypes";
import { IChat, IGetMessage, ISendMessage } from "../../types/chat";

export const getChat = (api: IApi, query: IQueryParams): Promise<Page<IChat>> => {
    let url = "chat"
    return api
        .getPage(url, query)
        .then((result: PagedApiResponse<IChat>) => {
        return result.data;
        })
        .catch((error) => {
        return new Promise((resolve, reject) => {
            reject(error);
        });
    });
}

export const getMessage = (api: IApi, projectId: string, query: IQueryParams): Promise<Page<IGetMessage>> => {
    let url = `chat/${projectId}/message`
    return api
        .getPage(url, query)
        .then((result: PagedApiResponse<IGetMessage>) => {
        return result.data;
        })
        .catch((error) => {
        return new Promise((resolve, reject) => {
            reject(error);
        });
    });
}

export const sendMessage = (api: IApi, projectId: string, payload: ISendMessage): Promise<IGetMessage> => {
    let url = `chat/${projectId}/message`
    return api
        .post(url, payload)
        .then((result: ApiResponse<IGetMessage>) => {
            return result.data;
        })
        .catch((error) => {
            return new Promise((resolve, reject) => {
              reject(error);
        });
    });
}

export const deleteMessage = (api: IApi, projectId: string, messageIds: string[]): Promise<boolean> => {
    let url = `chat/${projectId}/message?`

    messageIds.forEach((id, index) => {
        url += `messageIds=${id}`;
        if (index < messageIds.length - 1) {
            url += '&';
        }
    });

    return api
        .deleteData(url)
        .then((result: ApiResponse<boolean>) => {
            return result.data;
        })
        .catch((error) => {
            return new Promise((resolve, reject) => {
              reject(error);
        });
    });
}

export const saveMessage = (api: IApi, projectId: string, messageIds: string[]): Promise<boolean> => {
    let url = `chat/${projectId}/message/save?`

    messageIds.forEach((id, index) => {
        url += `messageIds=${id}`;
        if (index < messageIds.length - 1) {
            url += '&';
        }
    });

    return api
        .post(url)
        .then((result: ApiResponse<boolean>) => {
            return result.data;
        })
        .catch((error) => {
            return new Promise((resolve, reject) => {
              reject(error);
        });
    });
}

export const unsaveMessage = (api: IApi, projectId: string, messageIds: string[]): Promise<boolean> => {
    let url = `chat/${projectId}/message/unsave?`

    messageIds.forEach((id, index) => {
        url += `messageIds=${id}`;
        if (index < messageIds.length - 1) {
            url += '&';
        }
    });

    return api
        .post(url)
        .then((result: ApiResponse<boolean>) => {
            return result.data;
        })
        .catch((error) => {
            return new Promise((resolve, reject) => {
              reject(error);
        });
    });
}