import styled from "@emotion/styled";

export const Wrapper = styled.section`
  .flex_1 {
    background-color: var(--Grey200);
    margin-left: -40px;
    margin-right: -40px;
    padding: 20px 40px;
    @media (min-width: 992px) and (max-width: 1300px) {
      margin-left: -20px;
      margin-right: -20px;
      padding: 20px;
    }
  }
  .flex-budget {
    width: 100%;
  }

  @media (max-width: 991px) {
    .negotiable_budget {
      margin-top: 15px;

      > div:nth-of-type(2) {
        margin-bottom: 20px;
      }
    }
    .flex-budget {
      flex-direction: column;
      align-items: flex-start;
      row-gap: 10px;

      > div:nth-of-type(2) {
        display: none;
      }
    }
  }
  .custombtn1 {
    @media (max-width: 430px) {
      display: none;
    }
  }
  .custombtn2 {
    @media (min-width: 431px) {
      display: none;
    }
  }
`;
export const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 400px) {
    flex-direction: column;
    align-items: flex-start;
    row-gap: 20px;
  }
`;
export const VStack = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;
export const Header = styled.div`
  color: var(--GreyscaleTextIconBody);
  font-size: 24px;
  font-weight: 700;
  line-height: 150%;
  letter-spacing: -1.8px;

  @media (min-width: 992px) and (max-width: 1300px) {
    font-size: 22px;
  }
`;
export const Text = styled.div`
  color: var(--GreyscaleTextIconSubtitle);
  line-height: 150%;
  font-size: 14px;
`;
export const Section = styled.section`
  margin-top: 30px;
  row-gap: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  margin-bottom: 2%;
`;
export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 15px;

  @media (max-width: 991px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;
export const Order = styled.div`
  .container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #eee;
    border-radius: 50%;
  }

  /* On mouse hover, add a grey background color */
  .container:hover input ~ .checkmark {
    background-color: #ccc;
  }

  /* When the radio button is checked, add a background */
  .container input:checked ~ .checkmark {
    background-color: var(--PrimarySurfaceDefault);
  }

  /* Create the indicator (the dot/circle - hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
  }

  /* Show the indicator (dot/circle) when checked */
  .container input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the indicator (dot/circle) */
  .container .checkmark:after {
    top: 6px;
    left: 6px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: white;
  }
`;
export const Tabs = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
  cursor: pointer;
  margin: 10px 0px;

  @media (min-width: 992px) and (max-width: 1300px) {
    width: 100%;
  }
  @media (max-width: 991px) {
    width: calc(100vw - 35px);
  }
`;
export const Select = styled.div`
  font-weight: 500;
  line-height: 150%;
  font-size: 14px;
  border-radius: 100px;
  border: 1px solid #d0d5dd;
  padding: 8px 16px;
`;
export const Textarea = styled.textarea`
  color: var(--GreyscaleTextIconBody);
  font-size: 17px;
  line-height: 150%;
  border: 1.5px solid var(--Grey200);
  background: none;
  border-radius: 4px;
  padding: 10px 16px !important;
  font-weight: 500;
  width: calc(100vw - 450px);

  ::placeholder {
    color: var(--GreyscaleSurfaceDisabled);
    font-weight: 400;
  }

  @media (min-width: 992px) and (max-width: 1300px) {
    width: calc(100vw - 310px);
  }

  @media (max-width: 991px) {
    width: calc(100vw - 35px);
  }
`;
export const Attach = styled.div`
  font-size: 17px;
  cursor: pointer;
  text-decoration: underline;
  font-weight: 600;
  margin-left: 7px;
  position: relative;
  color: #297dfd;
`;

export const OthersTextBox = styled.input`
  background: var(--Grey100);
  border: 1px solid var(--Grey200);
  outline: none;
  padding: 10px 16px;
  font-size: 14px;
  border-radius: 5px;
  width: calc(100vw - 450px);
  font-family: jost;
  color: #6a7368;
  margin-top: 1%;
`;
