import styled from "@emotion/styled";

export const Wrapper = styled.section`
  .flex_1 {
    background-color: var(--Grey200);
    margin-left: -40px;
    margin-right: -40px;
    padding: 20px 40px;

    @media (min-width: 992px) and (max-width: 1300px) {
      margin-left: -20px;
      margin-right: -20px;
      padding: 20px;
    }
  }
`;
export const VStack = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;
export const Header = styled.div`
  color: var(--GreyscaleTextIconBody);
  font-size: 24px;
  font-weight: 700;
  line-height: 150%;
  margin-bottom: 10px;
`;
export const Text = styled.div`
  color: var(--GreyscaleTextIconSubtitle);
  line-height: 150%;
  font-size: 17px;
`;
export const Spacer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: calc(100vw - 450px);

  @media (min-width: 992px) and (max-width: 1300px) {
    width: calc(100vw - 310px);
  }
  @media (max-width: 991px) {
    flex-direction: column;
    row-gap: 10px;
    width: calc(100vw - 35px);
  }
`;