import { toast } from "react-toastify";
import { ToastErrorIcon, ToastSuccessIcon } from "../components/Notifications/toastIcons";

const useNotification = () => {
  const notify = (message: string, type: "success" | "error") => {
    toast(message, {
      type: type,
      position: "bottom-right",
      autoClose: 2500,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      style: {
        backgroundColor: type === "success" ? "#BAFFAD" : "#FFCCCC",
        color: "#000000",
        fontSize: "15px",
        fontWeight: "500",
        fontFamily: "Jost"
      },
      icon: type === "success" ? ToastSuccessIcon : ToastErrorIcon,
    });
  };

  return {
    notify,
  };
};
export default useNotification;