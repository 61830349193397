import styled from "@emotion/styled";

export const Wrapper = styled.section`
  background-color: var(--GreyscaleSurfaceSubtle);
  width: calc(100vw - 350px);
  height: 120px;
  border: 1px solid var(--Grey200);
  display: flex;
  align-items: center;
  column-gap: 30px;
  position: relative;
  z-index: 1000000;
  padding-top: 20px;

  @media (min-width: 992px) and (max-width: 1300px) {
    width: calc(100vw - 250px);
    column-gap: 15px;
  }
  @media (max-width: 991px) {
    width: 100vw;
    border-left: 0px;

    .active_state .bar {
      background-color: transparent !important;
    }
    .active_state .bar::before {
      transform: translateY(0) rotate(-45deg);
    }
    .active_state .bar::after {
      transform: translateY(0) rotate(45deg);
    }
    .active_state nav {
      transform: translateX(-4.8%);
      transition: ease-in-out 300ms;
      background-color: var(--GreyscaleSurfaceSubtle);
    }
    .active_search_state #search_bar {
      transform: translateX(0%);
      transition: ease-in-out 300ms;
      background-color: var(--GreyscaleSurfaceSubtle);
    }
    #search_bar input {
      padding-left: 40px !important;
    }
  }
`;
export const Nav = styled.nav`
  display: none;

  @media (max-width: 991px) {
    display: block;
    position: fixed;
    z-index: 1000000;
    top: 100px;
    left: 5%;
    width: 100%;
    height: 100%;
    transform: translateX(-200%);
    transition: ease-out 300ms;
  }
`;
export const SearchBar = styled.section`
  display: none;

  @media (max-width: 991px) {
    display: block;
    position: fixed;
    z-index: 10000000;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    transform: translateX(100%);
    transition: ease-out 300ms;
  }
`;
export const Item = styled.section`
  display: flex;
  align-items: center;
  column-gap: 10px;
  cursor: pointer;
  padding: 20px;
  margin: 5px 3% 10px 5px;
  position: relative;
  border-bottom: 1px solid var(--Grey200);
`;
export const SmallContainer = styled.section`
  display: none;
  width: 100vw;

  @media (max-width: 991px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: auto;

    .hamburger {
      width: 45px;
      height: 45px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      margin-left: 10px;
    }
    .hamburger .bar {
      position: relative;
      max-width: 100%;
      width: 60%;
      margin: auto;
      height: 2px;
      background-color: var(--GreyscaleSurfaceBtBackground);
      border-radius: 5px;
      transition: 0.5s;
    }
    .bar::after,
    .bar::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: var(--GreyscaleSurfaceBtBackground);
      border-radius: 5px;
      transition: 1s;
    }
    .bar::before {
      transform: translateY(-8px);
    }
    .bar::after {
      transform: translateY(8px);
    }
  }
`;
export const SearchContainer = styled.div`
  padding-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 5px;
`;
export const InputContainer = styled.div`
  position: relative;
  width: 35vw;
  margin-left: 10px;

  @media (min-width: 992px) and (max-width: 1300px) {
    width: 30vw;
  }
  @media (max-width: 991px) {
    display: none;
  }
`;
export const Input = styled.input`
  padding: 12px 20px;
  border-radius: 100px;
  border: 1px solid var(--GreyscaleBorderDisabled);
  outline: none;
  width: 100%;
  font-size: 17px;
  color: #808080;

  ::placeholder {
    color: #808080;
    font-size: 17px;
  }

  @media (min-width: 992px) and (max-width: 1300px) {
    font-size: 15px;
    padding: 10px 20px;

    ::placeholder {
      font-size: 15px;
    }
  }
`;
export const Day = styled.div`
  font-size: 14px;
  color: var(--GreyscaleBorderDarker);
  margin-left: auto;

  @media (max-width: 991px) {
    display: none;
  }
`;
export const Flex = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;
  margin-right: 40px;

  @media (min-width: 992px) and (max-width: 1300px) {
    margin-right: 20px;

    > img {
      width: 30%;
    }
  }
  @media (max-width: 991px) {
    display: none;
  }
`;
export const Text = styled.div`
  font-size: 17px;
  line-height: 150%;
  font-weight: 600;

  @media (min-width: 992px) and (max-width: 1300px) {
    font-size: 15px;
  }
`;
export const Header = styled.div`
  color: var(--GreyscaleTextIconBody);
  font-size: 35px;
  font-weight: 700;
  line-height: 150%;
  letter-spacing: -1.8px;
  display: flex;
  gap: 10px;
  position: relative;
  z-index: 1600;

  @media (min-width: 992px) and (max-width: 1300px) {
    font-size: 28px;
  }
`;