import React, { useContext, useEffect, useState } from "react";
import Proposals from "../../../components/Proposals";
import Prompts from "../../../components/Prompts";
import CustomInput from "../../../components/Input";
import { SelectChangeEvent } from "@mui/material";
import Button from "../../../components/Button";
import { ArrowRight } from "phosphor-react";
import CustomSelect from "../../../components/Select";
import { Wrapper, Flex, VStack, Col, Header, Text, SubHeader } from "./style3";
import { Country } from "country-state-city";
import { makePayment } from "../../../services/api/projectApi";
import useNotification from "../../../hooks/useNotification";
import { useNavigate, useParams } from "react-router-dom";
import { IGetProject } from "../../../types/project";
import { AppContext } from "../../../context";

interface PendingPaymentTabProps {
  selectedProject: IGetProject;
  setIndex: React.Dispatch<React.SetStateAction<number>>
}

const PendingPaymentTab = ({ selectedProject, setIndex }: PendingPaymentTabProps) => {
  const { projectId } = useParams();
  const { api, lookup, user } = useContext(AppContext);
  const [name, setName] = useState(`${user?.firstName} ${user?.lastName}`);
  const [country, setCountry] = useState(user?.country ?? "");
  const [address, setAddress] = useState(user?.address ?? "");
  const [isDisabled, setIsDisabled] = useState(true);
  const { notify } = useNotification();
  const navigate = useNavigate();

  const countryData = Country.getAllCountries().map((item) => {
    return { title: item.name, value: item.name };
  });
  const budget = {
    from: selectedProject?.proposals[selectedProject.proposals.length - 1]
      .budgetFrom,
    to: selectedProject?.proposals[selectedProject.proposals.length - 1]
      .budgetTo,
  };

  const handlePayNow = async () => {
    try {
      if (!api) return;
      projectId && (await makePayment(api, projectId));
      setIndex(4)
    } catch (error: any) {
      notify(
        "Error making payment, Please try that again or contact support if issue persists",
        "error"
      );
    }
  };

  const getTimeline = () => {
    return lookup?.ProjectTimeline.find(
      (item) => item.key == selectedProject?.timeline
    )?.description;
  };

  useEffect(() => {
    if (name && country && address) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [name, country, address]);

  return (
    <Wrapper>
      <div className="flex_1">
        <VStack>
          <Header>Payment Method</Header>
          <Text>
            In adherence to Algocipher's policy, initiating project work
            necessitates the payment of the total agreed amount. <br />
            This is integral to the commencement of the project and aligns with
            our established procedures to ensure a seamless and efficient
            workflow.
          </Text>
        </VStack>
      </div>
      <Proposals
        budget={{
          from: budget?.from,
          to: budget?.to,
          cost: selectedProject.cost,
        }}
        timeline={getTimeline()}
      />
      <SubHeader>Billing Info</SubHeader>
      <Col>
        <Flex>
          <VStack>
            <Prompts color="var(--Grey500)" title="Name" />
            <CustomInput
              placeholder="Please enter full name"
              value={name}
              half
              type="text"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setName(e.target.value)
              }
            />
          </VStack>

          <VStack>
            <Prompts color="var(--Grey500)" title="Country or Region" />
            <CustomSelect
              value={country}
              handleChange={(e: SelectChangeEvent) =>
                setCountry(e.target.value)
              }
              size="14px"
              half
              data={countryData}
            />
          </VStack>
        </Flex>

        <div>
          <Prompts color="var(--Grey500)" title="Home or office address " />
          <CustomInput
            placeholder="Please enter address"
            value={address}
            type="text"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setAddress(e.target.value)
            }
          />
        </div>
      </Col>

      <Col>
        <Flex className="btn_container_1">
          <Button
            title="Cancel Payment"
            bg="var(--GreyscaleSurfaceBtBackground)"
            color="var(--GreyscaleSurfaceSubtle)"
            budget
            w="47%"
            iconRight
            icon={
              <ArrowRight
                size={25}
                weight="thin"
                color="var(--GreyscaleSurfaceSubtle)"
              />
            }
            onClick={() => navigate("/projects")}
          />

          <Button
            title="Pay Now"
            bg="var(--PrimaryBorderLighter)"
            color="#FFF"
            budget
            w="47%"
            iconRight
            icon={<ArrowRight size={25} weight="thin" color="#FFF" />}
            onClick={() => !isDisabled && handlePayNow()}
            disabled={isDisabled}
          />
        </Flex>
      </Col>
    </Wrapper>
  );
};

export default PendingPaymentTab;
