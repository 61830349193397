import { ApiResponse, Page, PagedApiResponse } from "../../types/apiResponse";
import { ICalendar, IScheduleCall, ISingleCall, ICall } from "../../types/call";
import { IApi, IQueryParams } from "../../types/apiTypes";


export const getCalls = (api: IApi, query: IQueryParams): Promise<Page<ICall>> => {
  let url = "call"
  return api
    .getPage(url, query)
    .then((result: PagedApiResponse<ICall>) => {
      return result.data;
    })
    .catch((error) => {
      return new Promise((resolve, reject) => {
        reject(error);
      });
  });
};

export const getAvailableCallDate = (api: IApi): Promise<ICalendar[]> => {
  let url = "call/calendar"
  return api
    .get(url)
    .then((result: ApiResponse<ICalendar[]>) => {
      return result.data
    })
    .catch((error) => {
      return new Promise((resolve, reject) => {
        reject(error);
      })
  })
}

export const getAvailableCallTime = (api: IApi, query: string): Promise<string[]> => {
  let url = `call/calendar/${query}`
  return api
    .get(url)
    .then((result: ApiResponse<string[]>) => {
      return result.data
    })
    .catch((error) => {
      return new Promise((resolve, reject)=> {
        reject(error);
      })
    })
}

export const scheduleCall = (api: IApi, payload: IScheduleCall): Promise<boolean> => {
  let url = "call"
  return api
    .post(url, payload)
    .then((result: ApiResponse<boolean>) => {
      return result.data;
    })
    .catch((error) => {
      return new Promise((resolve, reject) => {
        reject(error);
      });
    });
}

export const getSingleCall = (api: IApi, callId: string): Promise<ISingleCall> => {
  let url = `call/${callId}`
  return api
    .get(url)
    .then((result: ApiResponse<ISingleCall>) => {
      return result.data
    })
    .catch((error) => {
      return new Promise((resolve, reject)=> {
        reject(error);
      })
    })
}

export const getCallLink = (api: IApi, callId: string): Promise<string> => {
  let url = `call/${callId}/link`
  return api
    .get(url)
    .then((result: ApiResponse<string>) => {
      return result.data
    })
    .catch((error) => {
      return new Promise((resolve, reject)=> {
        reject(error);
      })
    })
}

export const deleteCall = (api: IApi, callId: string): Promise<boolean> => {
  let url = `call/${callId}`
  return api
    .deleteData(url)
    .then((result: ApiResponse<boolean>) => {
      return result.data
    })
    .catch((error) => {
      return new Promise((resolve, reject) => {
        reject(error);
      });
    });
}