import React, { useContext } from "react";
import { AppContext } from "../../context";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";

const Wrapper = styled.section`
  .avatar_img {
    width: 48px;
    height: 48px;
    border-radius: 100%;
    resize-mode: contain;
  }
`;
const Placeholder = styled.div`
  display: flex;
  justify-content: center;
  column-gap: 3px;
  align-items: center;
  width: 48px !important;
  height: 48px;
  background: var(--GreyscaleSurfaceDefault);
  border-radius: 100%;
  cursor: pointer;
`;

const UserImage = () => {
  const { user } = useContext(AppContext);
  const navigate = useNavigate();
  return (
    <Wrapper onClick={() => navigate("/settings")}>
      {user?.imageUri ? (
        <img src={user?.imageUri} className="avatar_img" />
      ) : (
        <Placeholder>
          {/* <div>{user?.firstName.slice(0, 1)}</div>
                    <div>{user?.lastName.slice(0, 1)}</div> */}
        </Placeholder>
      )}
    </Wrapper>
  );
};

export default UserImage;
