import styled from "@emotion/styled";

export const Flex = styled.div`
  display: flex;
  justify-content: right;
  align-items: center;
  padding-bottom: 20px;

  @media (min-width: 992px) and (max-width: 1300px) {
    margin-right: 10px;
  }
`;
export const VStack = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;
export const Header = styled.div`
  color: var(--GreyscaleTextIconBody);
  font-size: 30px;
  font-weight: 700;
  line-height: 150%;
  letter-spacing: -1.8px;

  @media (min-width: 992px) and (max-width: 1300px) {
    font-size: 28px;
  }
`;
export const Text = styled.div`
  color: var(--GreyscaleTextIconBody);
  line-height: 150%;
  font-size: 17px;
  letter-spacing: 0.5px;
`;
export const Hr = styled.hr`
  border: 1px solid var(--GreyscaleBorderDefault);
  margin: 15px -40px;

  @media (min-width: 992px) and (max-width: 1300px) {
    margin: 15px -20px;
  }
  @media (max-width: 991px) {
    margin: 15px -15px;
  }
`;
export const Empty = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;
`;
export const EmptyContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 30px;
  margin-top: 50px;

  @media (max-width: 576px) {
    > img {
      width: 100%;
    }
    > div:nth-of-type(1) {
      text-align: center;
    }
  }
`;
export const TSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 20px;
`;
export const FlexTable = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;
  min-width: 200px;

  @media (max-width: 1300px) {
    > img {
      width: 35px;
    }
  }
`;
export const Status = styled.div`
  border-radius: 100px;
  padding: 5px 8px;
  text-align: center;
  width: fit-content;
  font-size: 17px;
  font-weight: 500;
  min-width: 120px;
`;
export const TableText = styled.div`
  color: var(--GreyscaleTextIconBody);
  line-height: 150%;
  font-size: 17px;
  min-width: 100px;
`;
export const TContainer = styled.div`
  background-color: #d9d9d9;
  border-radius: 100px;
  width: 100px;
  height: 6px;
`;
export const Timeline = styled.div`
  height: 6px;
  border-radius: 100px;
`;

