import React, { useContext, useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Sidebar from "../sidebar/sidebar";
import gridlines from "../../assets/images/gridlines2.svg";
import TopBar from "../topbar/topbar";
import { useLocation } from "react-router-dom";
import { routes } from "../../routes/route";
import { Flex, Gridlines, InnerWrapper, Main, Spacer, Wrapper } from "./style";
import { AppContext } from "../../context";
import LoadingScreen from "../../components/Loading/Screen";

interface IProps {
  children: React.ReactNode;
}

const MainLayout = ({ children }: IProps) => {
  const [route, setRoute] = useState<any>();
  const { user } = useContext(AppContext);
  const location = useLocation();

  useEffect(() => {
    const path = location.pathname;
    var matchedRoute =
      routes.find((x) => {
        return path.includes(x.path);
      }) ?? routes.find((x) => x.path === "/projects");
    setRoute(matchedRoute);
  }, [location]);

  return !user ? (
    <LoadingScreen />
  ) : (
    <Wrapper>
      <Sidebar route={route} />
      <Flex>
        <TopBar
          title={route?.name}
          img={route?.inactiveIcon}
          subTitle={route?.subTitle}
        />
        <Spacer />
        <InnerWrapper>
          <Main>{children}</Main>
          <Gridlines src={gridlines} alt="gridlines" />
        </InnerWrapper>
      </Flex>
      <ToastContainer />
    </Wrapper>
  );
};

export default MainLayout;
