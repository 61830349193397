import styled from "@emotion/styled";

export const Wrapper = styled.main`
  background-color: var(--Grey100);
  height: 100vh;
  display: flex;
`;
export const InnerWrapper = styled.section`
  height: calc(100vh - 100px - 20px);
  background-color: var(--GreyscaleSurfaceSubtle);
  width: calc(100vw - 350px);
  padding: 20px 40px;
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
  margin-bottom: 30px;

  ::-webkit-scrollbar {
    width: 22px;
  }
  ::-webkit-scrollbar-track {
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    background: #1dc200;
    border-radius: 10px;
  }

  @media (min-width: 992px) and (max-width: 1300px) {
    width: calc(100vw - 250px);
    padding: 15px 20px;
  }
  @media (max-width: 991px) {
    width: 100vw;
    padding: 15px;
  }
`;
export const Flex = styled.section`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: var(--GreyscaleSurfaceSubtle);
  overflow-x: hidden;
`;
export const Spacer = styled.section`
  width: calc(100vw - 350px);
  height: 20px;
  background-color: var(--Grey100);

  @media (min-width: 992px) and (max-width: 1300px) {
    width: calc(100vw - 250px);
  }
  @media (max-width: 991px) {
    width: 100vw;
  }
`;
export const Main = styled.section`
  position: relative;
  z-index: 100;
`;
export const Gridlines = styled.img`
  position: absolute;
  width: 20%;
  bottom: 0;
  right: 0;
`;