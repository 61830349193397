import { CaretLeft, MagnifyingGlass } from "phosphor-react";
import { useContext, useState } from "react";
import { AppContext } from "../../context";
import { formatDate } from "../../services/helpers/helpers";
import { routes } from "../../routes/route";
import { Link, useLocation, useNavigate } from "react-router-dom";
import UserImage from "../../components/UserImage";

// assets
import logo from "../../assets/images/products-logo.svg";
import {
  Day,
  Flex,
  Header,
  Input,
  Item,
  Nav,
  SearchBar,
  SearchContainer,
  SmallContainer,
  Wrapper,
  Text,
} from "./style";

const TopBar = ({ title, img, subTitle }: any) => {
  const today = new Date();
  const formattedDate = formatDate(today);
  const { isSidebarOpen, setSidebarOpen } = useContext(AppContext);
  const [isSearchBarOpen, setSearchBarOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const handleToggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  const handleToggleSearchbar = () => {
    setSearchBarOpen(!isSearchBarOpen);
  };

  const handleRouteClick = (item: any) => {
    navigate(item);
    setSidebarOpen(!isSidebarOpen);
  };

  return (
    <Wrapper>
      <div
        className={`${isSidebarOpen ? "active_state" : ""} ${
          isSearchBarOpen ? "active_search_state" : ""
        }`}
      >
        <Nav>
          {routes.map((item, index) => (
            <Item key={index} onClick={() => handleRouteClick(item.path)}>
              <img
                src={
                  location.pathname.startsWith(item.path)
                    ? item.activeIcon
                    : item.inactiveIcon
                }
                alt="icons"
              />
              <Text
                style={{
                  fontWeight: location.pathname.startsWith(item.path)
                    ? 600
                    : 500,
                  color: location.pathname.startsWith(item.path)
                    ? "var(--PrimaryBorderDefault)"
                    : "var(--ButtonsSurfaceDisabled)",
                }}
              >
                {item.name}
              </Text>
            </Item>
          ))}
        </Nav>
        <SmallContainer>
          <div className="hamburger" onClick={() => handleToggleSidebar()}>
            <div className="bar"></div>
          </div>

          <img src={logo} alt="logo" style={{ width: "150px" }} />

          <MagnifyingGlass
            size={30}
            style={{ marginRight: "20px" }}
            onClick={handleToggleSearchbar}
          />
        </SmallContainer>
        <SearchBar id="search_bar">
          <SearchContainer>
            <CaretLeft
              size={24}
              weight="bold"
              onClick={handleToggleSearchbar}
            />
            <div style={{ position: "relative" }}>
              <Input
                type="text"
                placeholder="Search"
                style={{ width: "90vw" }}
              />
              <MagnifyingGlass
                size={20}
                style={{
                  position: "absolute",
                  top: "50%",
                  transform: "translateY(-50%)",
                  left: "15px",
                }}
              />
            </div>
          </SearchContainer>
        </SearchBar>
      </div>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <Header>
          <img src={img} /> {title}
        </Header>
        <p
          style={{
            color: "#6A7368",
            fontFamily: "jost",
            fontWeight: "400",
            fontSize: "17px",
            paddingLeft: "45px",
            position: "relative",
            top: "-10px",
          }}
        >
          {subTitle}
        </p>
      </div>
      <Day>{formattedDate}</Day>
      <Flex>
        <Link to={"/settings"}>
          <UserImage />
        </Link>
      </Flex>
    </Wrapper>
  );
};

export default TopBar;
