import NotificationMessageIcon from "../../assets/icons/notification-message.svg";
import NotificationCallIcon from "../../assets/icons/notification-call.svg";
import NotificationFileIcon from "../../assets/icons/notification-file.svg";
import NotificationBudgetIcon from "../../assets/icons/notification-budget.svg";



export const notificationTypes = [
    {
        type: "callCanceled",
        img: NotificationCallIcon,
        route: "calls",
    },
    {
        type: "callScheduled",
        img: NotificationCallIcon,
        route: "calls",
    },
    {
        type: "chatMessage",
        img: NotificationMessageIcon,
        route: "chat"
    }
]