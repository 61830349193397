import { useContext, useState } from "react";
import Button from "../../components/Button";
import { Avatar, Typography } from "@mui/material";
import useNotification from "../../hooks/useNotification";
import { useNavigate } from "react-router-dom";
import { getCallLink, getCalls } from "../../services/api/callApi";
import { useEffect } from "react";
import { ICall } from "../../types/call";
import { Flex, Empty, EmptyContent, Text, FlexTable } from "./style";
import CustomTable from "../../components/Shared/CustomTable";
import { DateTimeFormat } from "../../services/helpers/useChangeDateFormat";

// assets
import calendarIcon from "../../assets/icons/calendar.svg";
import AlgocipherAlc from "../../assets/icons/algocipher-alc.svg";
import dot from "../../assets/icons/dot.svg";
import orangeDot from "../../assets/icons/orange-dot.svg";
import whiteDot from "../../assets/icons/white-dot.svg";
import emptyCart from "../../assets/icons/empty-cart.svg";
import activeCall from "../../assets/icons/call-green.svg";
import expiredCall from "../../assets/icons/call-grey.svg";
import scheduledCall from "../../assets/icons/call-yellow.svg";
import canceledCall from "../../assets/icons/call-black.svg";

import { AppContext } from "../../context";
import { error } from "console";

const Calls = () => {
  const [calls, setCalls] = useState<ICall[]>([]);
  const [queryParams, setQueryParams] = useState({
    pageNumber: 1,
    pageSize: 10,
    sortBy: "time",
    filterBy: "",
    sortOrder: "asc",
    inputValue: "",
  });
  const [totalRecords, setTotalRecords] = useState(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { api, user } = useContext(AppContext);

  const { notify } = useNotification();
  const navigate = useNavigate();

  const getStatus = (date: string, status: string) => {
    const currentDate = new Date();
    const meetingDate = new Date(date);

    const diffInMs = meetingDate.getTime() - currentDate.getTime();
    const diffInMinutes = diffInMs / (1000 * 60);

    const canceled = status === "canceled";
    if (status === "canceled") {
      return {
        status: "canceled",
        bgColor: "none",
        color: "#000000",
        dot: dot,
        icon: canceledCall,
      };
    }
    if (diffInMinutes < -Number(process.env.REACT_APP_CALL_DURATION)) {
      return {
        status: "expired",
        bgColor: "none",
        color: "#90998F",
        dot: dot,
        icon: expiredCall,
      };
    } else if (diffInMinutes <= 0) {
      return {
        status: "join call",
        bgColor: "var(--PrimarySurfaceLighter)",
        color: "#F7F8F7",
        dot: whiteDot,
        icon: activeCall,
      };
    } else {
      return {
        status: "scheduled",
        bgColor: "none",
        color: "#FCA625",
        dot: orangeDot,
        icon: scheduledCall,
      };
    }
  };

  const getSetupBy = (key: string) => {
    if (key === "platform") {
      return {
        name: "Algocipher",
        icon: AlgocipherAlc,
      };
    } else {
      return {
        name: user?.firstName,
        icon: user?.imageUri,
      };
    }
  };
  const handlePageChanged = (page: number, size: number) => {
    const newQuery = { ...queryParams, pageNumber: page, pageSize: size };
    setQueryParams(newQuery);
  };

  const handleSortChanged = (sortBy: string, sortOrder: string) => {
    const newQuery = {
      ...queryParams,
      pageNumber: 1,
      sortBy: sortBy,
      sortOrder: sortOrder,
    };
    setQueryParams(newQuery);
  };

  const handleSearch = (searchValue: string) => {
    const newQuery = {
      ...queryParams,
      search: searchValue,
    };
    setQueryParams(newQuery);
  };

  // const now = new Date().getTime()
  // calls.sort((a,b)=> Math.abs(Date.parse(a.time) - now) - Math.abs(Date.parse(b.time) - now))

  const tableData = calls.map((item) => {
    const status = getStatus(item.time, item.status);
    const setupBy = getSetupBy(item.setupBy);
    return {
      id: item.id,
      meetingName: (
        <Typography sx={tableColumnStyle}>
          <Avatar src={status.icon} /> {item.meetingName}
        </Typography>
      ),
      time: (
        <FlexTable>
          <Typography sx={{ ...tableColumnStyle, minWidth: "auto" }}>
            {DateTimeFormat(item.time).formattedTime}
          </Typography>

          <div
            style={{
              width: "6px",
              height: "6px",
              borderRadius: "50%",
              backgroundColor: "var(--GreyscaleTextIconCaption)",
            }}
          />

          <Typography sx={{ ...tableColumnStyle, minWidth: "auto" }}>
            {DateTimeFormat(item.time).formattedDate}
          </Typography>
        </FlexTable>
      ),
      setupBy: (
        <Typography sx={tableColumnStyle}>
          <Avatar src={setupBy.icon} /> {setupBy.name}
        </Typography>
      ),
      projectName: (
        <Typography sx={tableColumnStyle}>{item.projectName}</Typography>
      ),
      status: (
        <FlexTable
          style={{
            backgroundColor: status.bgColor,
            border: `1.5px solid ${status.color}`,
            minWidth: "auto",
            width: "fit-content",
            padding: "4px 8px",
            borderRadius: "4px",
            marginRight: "20px",
          }}
        >
          <Text
            style={{
              color: status.color,
              textTransform: "uppercase",
            }}
          >
            {status.status}
          </Text>

          <img src={status.dot} style={{ width: "12px", height: "12px" }} />
        </FlexTable>
      ),
    };
  });

  const handleCallClick = (id: string) => {
    const callIndex = calls.findIndex((item) => item.id === id);

    if (callIndex !== -1) {
      const { status } = getStatus(
        calls[callIndex].time,
        calls[callIndex].status
      );

      if (status === "scheduled") {
        navigate(`/calls/${id}`);
      } else if (status === "join call") {
        try {
          api &&
            getCallLink(api, id).then((res) => {
              if (res) {
                navigate(res, { replace: true });
              } else {
                notify(
                  "Error joining call. Please try that again or contact support if the issue persists",
                  "error"
                );
              }
            });
        } catch (error) {
          notify(
            "Error joining call. Please try that again or contact support if the issue persists",
            "error"
          );
        }
      } else return;
    }
  };

  const fetchCallsData = async () => {
    if (!api) return;
    try {
      setIsLoading(true);
      const res = await getCalls(api, queryParams);
      if (res) {
        setCalls(res.result);
        setTotalRecords(res.total);
      }
    } catch (error: any) {
      notify(error.message, "error");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    api && fetchCallsData();
  }, [api, queryParams]);

  const emptyContent = (
    <Empty>
      <EmptyContent>
        <img src={emptyCart} alt="empty-cart" />
        <Text style={{ textAlign: "center", fontSize: "17px" }}>
          You haven't placed any calls on the platform yet.
          <br /> Once you do, they will be visible here.
        </Text>
        <Button
          onClick={() => navigate("/calls/schedule-a-call")}
          title="Schedule a call"
          bg="var(--PrimaryBorderLighter)"
          color="#FFF"
          icon={
            <img
              src={calendarIcon}
              style={{ marginBottom: "3px" }}
              alt="calendar"
            />
          }
        />
      </EmptyContent>
    </Empty>
  );
  return (
    <>
      <Flex>
        <Button
          title="Schedule a call"
          bg="var(--GreyscaleSurfaceBtBackground)"
          color="var(--GreyscaleSurfaceSubtle)"
          icon={
            <img
              src={calendarIcon}
              style={{ marginBottom: "3px" }}
              alt="calendar"
            />
          }
          onClick={() => navigate("/calls/schedule-a-call")}
        />
      </Flex>
      <CustomTable
        columns={columns}
        data={tableData}
        pageSize={queryParams.pageSize}
        pageNumber={queryParams.pageNumber}
        totalRecords={totalRecords}
        onPageChange={handlePageChanged}
        onSort={handleSortChanged}
        onSearch={handleSearch}
        onRowClick={handleCallClick}
        isLoading={isLoading}
        emptyContent={emptyContent}
      />
    </>
  );
};

export default Calls;

const tableColumnStyle = {
  fontFamily: "jost",
  fontWeight: "550",
  fontSize: "14px",
  lineHeight: "21px",
  color: "#222522",
  whiteSpace: "nowrap",
  overflow: "hidden",
  minWidth: "150px",
  textOverflow: "ellipsis",
  display: "flex",
  alignItems: "center",
  gap: "10px",
};
let columns = [
  {
    id: "meetingName",
    text: "Meeting name",
    canSort: true,
  },
  {
    id: "time",
    text: "Time and Date",
  },
  // {
  //   id: "date",
  //   text: "Date",
  // },
  {
    id: "setupBy",
    text: "Setup by",
  },
  {
    id: "projectName",
    text: "Project name",
    canSort: true,
  },
  {
    id: "status",
    text: "Call status",
    canSort: true,
  },
];
